import React, { history, useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import { useNavigate, useParams } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web';
import { ErrorMessage } from "@hookform/error-message";
//import checkAuthentication from "../../../utility/checkAuthentication";
//import { keycloak } from '../../../utility/keycloakConfig';
//import accountType from '../../../utility/keycloakConfig';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ReCAPTCHA from "react-google-recaptcha";
import Keycloak from 'keycloak-js';
import validator from 'validator';
import { useLoading } from "react-hook-loading";
import services from "../../../services/services";
import { requestObject } from "../../../utility/requestObject";
import { keycloak } from "../../../utility/config";
import jwt_decode from 'jwt-decode'
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { loadingComponent } from "../../../utility/Loading";
import Swal from "sweetalert2";
import { Block } from "@material-ui/icons";
import { Carousel } from 'react-responsive-carousel';

import CarouselComponent from "./CarouselComponent";
import UsNumberValidate from "../../../utility/usNumberValidate";
import { LoadingComponentForSubmit } from "../../../utility/loadingforsubmit";
import { SiteKey } from "../../constant";
const pageBodyWrapper = {
    display: "block",
    marginTop: 0
}

var pathName = window.location.pathname;

const Login = () => {


    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onKeyup",
    });
    const [authenticate, setAuthenticate] = useState(false);
    const navigate = useNavigate();
    const [emailError, setEmailError] = useState('');
    const [itemName, setFieldName] = useState('');
    const [captch, setcaptcha] = useState('');
    const [loading, setLoading] = useLoading("Please wait...");
    const validateEmail = (e) => {
        var email = e.target.value.replace(/^\s+|\s+$/gm, '');

        if (validator.isEmail(email.trim())) {
            setEmailError('');
        }
        else {
            setEmailError('Invalid email address')
        }

    }
    //    const {
    //      register,
    //      formState: { errors },
    //      handleSubmit
    //    } = useForm({
    //      criteriaMode: "all",mode: "onBlur"
    //    });

    function onChange(value) {
        setcaptcha(value)

    }


    useEffect(() => {

        $('#edit_mobile').usPhoneFormat();
        $("#mydiv").removeClass("container-fluid page-body-wrapper");

        if (keycloak.authenticated) {

            if (keycloak.token != "") {

                if (localStorage.getItem("realm") !== null) {

                    setAuthenticate(true);
                    localStorage.setItem("accToken", keycloak.token);
                    sessionStorage.setItem("fkAccountId", "1");
                    var accessTokenT = localStorage.getItem("accToken");
                    var decoded = jwt_decode(keycloak.token);
                    var rolemanage = decoded.resource_access;
                    localStorage.setItem("Userroles", JSON.stringify(rolemanage["dotstow_ui"].roles[0]));
                    //  window.location.href='/garage/dashboard';

                    var customerListReq =
                    {

                    };

                    var requestOptions = requestObject.RequestHeader(customerListReq);
                    services.getAccountType(requestOptions).then((res) => {
                        $("#loading").hide();
                        sessionStorage.setItem("accountType", res.rows.accountTypeName);
                        if (res.rows.accountTypeName == 'garage') {
                  
                            // window.location.href = '/garage/dashboard';
                            // sessionStorage.clear();
                            // localStorage.clear();
                            // navigate("/login");
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: "",
                                text: "Fleet users only accees the DotStow App",
                                showConfirmButton: true

                            }).then((result) => {

                                if (result.isConfirmed) {
                                    sessionStorage.clear();
                                    localStorage.clear();
                                    window.location.href = "/login";
                                }
                            });

                        } if (res.rows.accountTypeName == 'fleet') {
                          
                            window.location.href = "/fleetowner/Vehicle";

                        }

                    });
                }


            } else {
                sessionStorage.clear();
                localStorage.clear();
            }


        } else {

        }


    }, []);

    const scroolRedirect = (areaId) => {

        if (areaId == "garage_wrap" || areaId == "newsletter_wrap") {
            $('html,body').animate({
                scrollTop: $("." + areaId).offset().top
            },
                'smooth');
        }
        if (areaId == "garage_wrap") {
            document.getElementById("features").setAttribute("class", "nav-link active");
            $('#home').removeClass('active');
            $('#contactUs').removeClass('active');
        } if (areaId == "newsletter_wrap") {
            document.getElementById("contactUs").setAttribute("class", "nav-link active");
            $('#features').removeClass('active');
            $('#home').removeClass('active');
        } if (areaId == "home") {
            document.getElementById("home").setAttribute("class", "nav-link active");
            $('#contactUs').removeClass('active');
            $('#features').removeClass('active');
        }
    }

    const mobilevalidate = (e) => {
        $('#edit_mobile').usPhoneFormat();
    }
    const loginMethod = (e) => {
        var email = $("#userEmail").val();

        if (validator.isEmail(email)) {
            setEmailError('');

            var userDetails =
            {

                "email": email,

            };

            const requestOptions =
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(userDetails)
            };

            services.getUserByEmailId(requestOptions).then((res) => {
                // document.getElementById("edit_close").click();


                if (res.rows !== null) {



                    if (res.rows.tenantName !== null) {



                        sessionStorage.setItem("email", email);
                        localStorage.setItem("realm", res.rows.tenantName);
                        // console.log(res.rows);
                        // alert(res.rows.tenantName);
                        // const keycloaksetup=KeycloakSetup();

                        // alert("keycloaksetup=="+JSON.stringify(keycloaksetup))
                        // const keycloak= KeycloakSetup()
                        //   var authUrl="http://keycloak-dotstow-dev-alb-1135353619.us-east-2.elb.amazonaws.com/auth"
                        //  var authUrl=""
                        //  switch(res.rows.tenantName)
                        //  {
                        //    case "Dev":
                        //     authUrl= "http://keycloak-dotstow-dev-alb-1135353619.us-east-2.elb.amazonaws.com/auth";
                        //      break;
                        //     case "dotstow":
                        //      authUrl="http://keycloak-dotstow-qa-alb-1314093332.us-east-2.elb.amazonaws.com/auth";
                        //         break;
                        //       default:
                        //         authUrl= "http://keycloak-dotstow-dev-alb-1135353619.us-east-2.elb.amazonaws.com/auth";

                        //          break;
                        //  }

                        //   keycloak.authServerUrl=authUrl;
                        ///  keycloakAuth.login({idpHint: 'facebook'});
                        //keycloak.loginHint=email;

                        keycloak.realm = res.rows.tenantName;

                        keycloak.login({ loginHint: email });
                    }
                    else {


                        if (res.message === null || res.message === "" || res.message === undefined) {
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: "",
                                text: "Email not registered with DotStow",
                                showConfirmButton: true

                            }).then((result) => {

                                if (result.isConfirmed) {


                                }
                            });

                        } else {
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: "",
                                text: res.message,
                                showConfirmButton: true

                            }).then((result) => {

                                if (result.isConfirmed) {


                                }
                            });

                        }


                    }
                } else {
                    if (res.message === null || res.message === "" || res.message === undefined) {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: "",
                            text: "Email not registered with DotStow",
                            showConfirmButton: true

                        }).then((result) => {

                            if (result.isConfirmed) {


                            }
                        });

                    } else {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: "",
                            text: res.message,
                            showConfirmButton: true

                        }).then((result) => {

                            if (result.isConfirmed) {


                            }
                        });

                    }
                }            // keycloak.login('/garage/dashboard');

            });


        } else {
            setEmailError('Email address is required')
        }

        // if(keycloak.authenticated)
        // {

        //     navigate('/garage/dashboard');
        // }
        // else
        // {

        //     keycloak.login('/garage/dashboard');
        // }
    }
    const contact_us = async (event, error) => {

        if (captch == null || captch == "") {

            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "",
                text: "Please verify the captcha",
                showConfirmButton: true

            }).then((result) => {

                if (result.isConfirmed) {


                }
            });

        }
        else {
            // setLoading(true);
            $("#loadingforsubmit").show();
            var conactUs =
            {
                "firstName": $("#edit_fristName").val(),
                "lastName": $("#edit_lastName").val(),
                "phoneNumber": $("#edit_mobile").val(),
                "email": $("#edit_email").val(),
                "companyName": $("#edit_Comapny").val(),
                "message": $("#Message").val()

            };
            // var requestOptions=requestObject.RequestHeader(conactUs);
            const requestOptions =
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(conactUs)
            };
            services.sendMessageToAdmin(requestOptions).then((res) => {
                // setLoading(false);
                $("#loadingforsubmit").hide();
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: "",
                    text: res.message,
                    showConfirmButton: true

                }).then((result) => {

                    if (result.isConfirmed) {
                        navigate("/login");
                        $("#contact_form").trigger('reset');
                        // $('#rc-anchor-container').trigger('reset');
                        // ReCAPTCHA.trigger('reset');
                    }
                });
            })
        }


    }
    const handleOnEvent = async (event, error) => {

        if (event === 'onAuthSuccess') {

            if (keycloak.authenticated) {

                if (keycloak.token != "") {
                    $(".pp").hide();
                    if (localStorage.getItem("realm") !== null) {

                        setAuthenticate(true);
                        localStorage.setItem("accToken", keycloak.token);
                        sessionStorage.setItem("fkAccountId", "1");
                        var accessTokenT = localStorage.getItem("accToken");
                        var decoded = jwt_decode(keycloak.token);

                        var rolemanage = decoded.resource_access;
                        localStorage.setItem("Userroles", JSON.stringify(rolemanage["dotstow_ui"].roles[0]));
                        //  window.location.href='/garage/dashboard';

                        var customerListReq =
                        {

                        };

                        var requestOptions = requestObject.RequestHeader(customerListReq);
                        services.getAccountType(requestOptions).then((res) => {

                            sessionStorage.setItem("accountType", res.rows.accountTypeName);
                            $("#loading").hide();
                            if (res.rows.accountTypeName == 'garage') {
                           
                                Swal.fire({
                                    position: 'center',
                                    icon: 'error',
                                    title: "",
                                    text: "Fleet users only accees the DotStow App",
                                    showConfirmButton: true
    
                                }).then((result) => {
    
                                    if (result.isConfirmed) {
                                        sessionStorage.clear();
                                        localStorage.clear();
                                        window.location.href = "/login";
                                    }
                                });

                            } if (res.rows.accountTypeName == 'fleet') {
                              
                                window.location.href = "/fleetowner/Vehicle";

                            }

                        });
                    } else {
                        keycloak.logout();

                    }


                }


            } else {

            }
        } else {

        }
    }
    const InitialloadingComponent = (
        <div class="loader-wrapper" id="loading">
            <div class="truck-wrapper">
                <div class="truck">
                    <div class="truck-container"></div>
                    <div class="glases"></div>
                    <div class="bonet"></div>

                    <div class="base"></div>

                    <div class="base-aux"></div>
                    <div class="wheel-back"></div>
                    <div class="wheel-front"></div>

                    <div class="smoke"></div>
                </div>
            </div>
        </div>
    )
    return (
        <>
            {authenticate ? InitialloadingComponent :
                <ReactKeycloakProvider
                    authClient={keycloak}

                    LoadingComponent={InitialloadingComponent}
                    onEvent={(event, error) => handleOnEvent(event, error)}>
                    {LoadingComponentForSubmit}
                    <div class="bg-green">

                        <div class="p-3 login-center">
                            <div class="pt-5">
                                <div class="logo-dvr m-auto text-center">
                                    <img src="/img/logo-dotstow.svg" style={{ width: "111px" }} />
                                </div>
                                <div class="dvr-log text-center p-4">
                                    <h5 className="mb-3">Log in to Dotstow</h5>
                                    <hr/>
                                    <div class="my-4">
                                        <label for="exampleInputEmail1" class="form-label d-flex" style={{textTransform:"inherit"}}>Username or Email Address</label>
                                        <input type="email" class="form-control mb-2"  id="userEmail"
                                            onChange={(e) => validateEmail(e)} />
                                        <p class="error d-flex">{emailError}</p>
                                        <div id="abcd" class="error my-2 d-none">invalid message here in red color</div>
                                        <button type="submit" style={{minHeight:"40px"}} class="btn btn-primary w-100" id="loginCall" onClick={() => loginMethod()}>Proceed</button>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    

                    </div>
                </ReactKeycloakProvider>
            }
        </>
    )
};

Login.propTypes = {};

Login.defaultProps = {};

export default Login;