import React,{history,useEffect, useState, useMemo } from "react";
import Tableheader from "../../../../utility/tableheader";
import Pagination from "../../../../utility/pagination";
import vehicle_api from "../../vehicle/vehicle_lists";
import Search from "../../../../utility/search";
import {useNavigate , useParams } from "react-router-dom";
import Swal from "sweetalert2";
import $ from "jquery";
import CheckAuthentication from "../../../../utility/checkAuthentication";
import getcustomerLists from "../../customer/customer_api";
import { requestObject } from "../../../../utility/requestObject";
import services from "../../../../services/services";
import document_api from "../../document/document_api";
import customerVehicle from "./customer_vehicle_fleetowner";
import customer_doc_api from "../../document/customer_doc_api";
import moment from "moment";
import { loadingComponent } from "../../../../utility/Loading";
import { document_details } from "../../../../utility/DocumentDetails";
import { Driver, Owner } from "../../../constant";
import { deleteDocuments } from "../../../../utility/sendtocustomer";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";
const FleetVehicle = () => {
    const [sortingField, setSortingField] = useState("");
    const [sortingOrder, setSortingOrder] = useState("");

    const [searchVehicle, setSearchVehicle] = useState("");

    var roles = localStorage.getItem("Userroles");
    // alert(roles);

     const inlineCss=
     {
         textAlign:"left"
     }
     const inlineWidthFix=
     {
         width:"99px"
     }
     const inlineWidth30=
     {
         width:"32%"
     }
     const inlineWidth26=
     {
         width:"26%"
     }
     const inlineCssCenter=
     {
         textAlign:"center"
     }
     const onSortingChangeVehicle = (field) =>
     {
       const order =
           field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

       setSortingField(field);
       setSortingOrder(order);
    //    vehicle_api("1",currentPage,ITEMS_PER_PAGE,"",ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setVehicleList,setTotalRecord,setdisplayPage,sortingField,sortingOrder);
    customerVehicle(customerId,"1",ITEMS_PER_PAGE,"","",ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setVehicleList,setTotalRecord,setdisplayPage,field?field:"",order,"");

     };
     const [customerName, setCustomerName] = useState("");
     const [documentTitle, setDocumentTitle] = useState("");
     const headers =
     [
    //    { name: "Vehicle Number", field: "Vehicle number",sortable: true, inlineStyle:inlineCss, inlineStyle:inlineWidth26 },
       { name: "Unit Number", field: "Unit number",sortable: true,inlineStyle:inlineCss, inlineStyle:inlineWidth30 },
       { name: "VIN Number ", field: "Vin number",sortable: true,inlineStyle:inlineCss, inlineStyle:inlineWidth30},
       { name: "Type", field: "vehicleTypeName",sortable: true,inlineStyle:inlineCss,inlineStyle:inlineWidth26  },
       { name: "SubType", field: "SubType",sortable: false,inlineStyle:inlineCss, inlineStyle:inlineWidth30 },
    //    { name: "Contact Number", field: "customerNumber",inlineStyle:inlineCss },
    //    { name: "View", field: "view"},
       // { name: "Email", field: "Email",sortable: true,inlineStyle:inlineCss },
       { name: "Actions", inlineStyle:inlineWidthFix }
     ];

     const headersDoc =
     [
         //    { name: "Category", field: "documentCategoryName",sortable: true,inlineStyle:inlineCss },
         { name: "Type", field: "documentSubCategoryName",sortable: true,inlineStyle:inlineCss },
         { name: "Title", field: "Title",sortable: true, thStyle: 'width="30%"',inlineStyle:inlineCss },
    //    { name: "Sub Category", field: "documentSubCategoryName",sortable: true,inlineStyle:inlineCss },
       { name: "Unit Number", field: "Unit Number",sortable: true,inlineStyle:inlineCss },
    //    { name: "Doc Date", field: "Document Date", sortable: true, inlineStyle: inlineCss},
       { name: "Expiry Date", field: "expiryDate",sortable: true,inlineStyle:inlineCss },
    //    { name: "Created By",field: "createUser",inlineStyle:inlineCss},
       { name: "Actions", thStyle: 'width="99px"' }
     ];
     const [vehicleId, setVehicleId] = useState();
   const [vechileLists,setVehicleList] = useState([]);
   const [vehicleListDocs, setVehicleListDocs] = useState([]);
   const [totalRecors,setTotalRecord] = useState([]);
   const [totalRecors1,setTotalRecord1] = useState([]);
   const [totalItems, setTotalItems] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);
   const [totalpages, settotalPages] = useState();
   const [displayPage, setdisplayPage] = useState([]);
   const [ITEMS_PER_PAGE, setitemperpage] = useState(25);
   const [ITEMS_PER_PAGE1, setitemperpage1] = useState(25);
   const [ extension, setfileExtension ] = useState("");
   const [totalItems1, setTotalItems1] = useState(0);
   const [currentPage1, setCurrentPage1] = useState(1);
   const [totalpages1, settotalPages1] = useState();
   const [displayPage1, setdisplayPage1] = useState([]);
   const [customerList,setCustomerList] = useState([]);
   const [customerId, setCustomerId] = useState("");
   const [documentList, setDocumentList] = useState([]);
   const [currentDocsPage, setCurrentDocsPage] = useState(5);
   const [totaldocspages, settotalDocsPages] = useState();
   const [totalDocsRecors,setTotalDocsRecord] = useState([]);
   const [totalDocsItems, setTotalDocsItems] = useState(0);
   const [displayDocsPage, setdisplayDocsPage] = useState([]);
   const [ITEMS_PER_PAGE_DOCS, setitemperdocspage] = useState(25);

   const navigate = useNavigate();



  /////////////////docs pagination////////////////////////////
  const handlePageChangedocument = (offset,triggerevent) =>
  {


   customer_doc_api(customerId,offset,ITEMS_PER_PAGE_DOCS,"",ITEMS_PER_PAGE_DOCS,setTotalDocsItems,settotalDocsPages,setCurrentDocsPage,setDocumentList,setTotalDocsRecord,setdisplayDocsPage,sortingField,sortingOrder,"displayItems");

  }
   /////////////////vehicle pagination////////////////////////////
   const handlePageChange = (offset,triggerevent) =>
   {

    customerVehicle(customerId,offset,ITEMS_PER_PAGE1,"",vehicleId,ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setVehicleList,setTotalRecord,setdisplayPage,sortingField,sortingOrder,"displayItems");
     //vehicle_api("1",offset,ITEMS_PER_PAGE,"",ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setVehicleList,setTotalRecord,setdisplayPage,sortingField,sortingOrder,"");

   }
   /////////////////////////////vehicle search/////////////////////////
   const searchList = (vehicleId) =>
   {

    customerVehicle(customerId,"1",ITEMS_PER_PAGE,vehicleId,"",ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setVehicleList,setTotalRecord,setdisplayPage,sortingField,sortingOrder,"displayItems");

   }
   const onSortingChangeDocument = (field) =>
   {
     const order =
         field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

     setSortingField(field);
     setSortingOrder(order);
     document_api("",currentPage,ITEMS_PER_PAGE1,"",ITEMS_PER_PAGE1,setTotalItems1,settotalPages1,setCurrentPage1,setDocumentList,setTotalRecord1,setdisplayPage1,"",field,order,"");

   };

   const redirect=(page)=>
   {

       navigate(page);

   }

   useEffect(() =>
   {
        accountType();
        sessionStorage.removeItem("resolveAll");
        $("#loadingforsubmit").hide();
        var navigationFrom=sessionStorage.getItem("naviationfrom");

        if(navigationFrom === "fleetDocument")
        {
            
            document.getElementById("pills-profile-tab").click();
            sessionStorage.removeItem("naviationfrom");
        }else
        {
            document.getElementById("pills-home-tab").click();
            sessionStorage.removeItem("naviationfrom");
        }

     if(CheckAuthentication)
     {
            // if(sessionStorage.getItem("selected")==="documents")
            // {
            //     sessionStorage.removeItem("documents");
            //     document.getElementById("pills-profile-tab").click();
            // }else
            // {
            //    document.getElementById("pills-home-tab").click(); 
            // }
          //  $("#vehicleData").show();
            customer_doc_api(customerId,"1",ITEMS_PER_PAGE_DOCS,"",ITEMS_PER_PAGE_DOCS,setTotalDocsItems,settotalDocsPages,setCurrentDocsPage,setDocumentList,setTotalDocsRecord,setdisplayDocsPage,"",sortingOrder,"displayItems");
        // document_api("", "1", ITEMS_PER_PAGE1, "", ITEMS_PER_PAGE1, setTotalItems1, settotalPages1, setCurrentPage1, setDocumentList, setTotalRecord1, setdisplayPage1, "", sortingField, sortingOrder, "diplayItems");
            $('#display_pages_in_items1').on('change', function() 
            {


                setitemperdocspage(this.value);
                customer_doc_api(customerId,"1",this.value,"",this.value,setTotalDocsItems,settotalDocsPages,setCurrentDocsPage,setDocumentList,setTotalDocsRecord,setdisplayDocsPage,sortingField,sortingOrder,"");
                //document_api("", "1", this.value, "", this.value, setTotalItems1, settotalPages1, setCurrentPage1, setDocumentList, setTotalRecord1, setdisplayPage1, "", sortingField, sortingOrder, "");

            });
           customerVehicle(customerId,"1",ITEMS_PER_PAGE,"","",ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setVehicleList,setTotalRecord,setdisplayPage,sortingField,sortingOrder,"displayItems");

           $('#display_pages_in_items').on('change', function() 
           {

             setitemperpage(this.value);
             customerVehicle(customerId,"1",this.value,"","",this.value,setTotalItems,settotalPages,setCurrentPage,setVehicleList,setTotalRecord,setdisplayPage,sortingField,sortingOrder,"displayItems");

           });
     }
     else
     {
        sessionStorage.clear();
        localStorage.clear();
       //navigate("/login");
         window.location.href="/login";
     }


   },[]);

   const searchCustomerList = (customerName) =>
   {

     setCustomerName(customerName);
     getcustomerLists("1",ITEMS_PER_PAGE,customerName,ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setCustomerList,setTotalRecord,setdisplayPage,sortingField,sortingOrder);

   }

    /////////////////////////////vehicle search/////////////////////////
    const searchListForDocs = (documentTitle) =>
    {
        setDocumentTitle(documentTitle);
        customer_doc_api(customerId,"1",ITEMS_PER_PAGE_DOCS,documentTitle,ITEMS_PER_PAGE_DOCS,setTotalDocsItems,settotalDocsPages,setCurrentDocsPage,setDocumentList,setTotalDocsRecord,setdisplayDocsPage,sortingField,sortingOrder,"diplayItems");
        //document_api("", "1", ITEMS_PER_PAGE1, "", ITEMS_PER_PAGE1, setTotalItems1, settotalPages1, setCurrentPage1, setDocumentList, setTotalRecord1, setdisplayPage1, searchText, sortingField, sortingOrder, "diplayItems");

    }
//    const handleChange = event =>
//    {

//      setCustomerId(event.target.value);

//      //onChange(event.target.value)
//    }
   const handleChange = event => {

    setCustomerId(event.target.value);
    // if (event.target.value !== "") {
    //     $("#vehicleData").show();
    // } else {
    //     $("#vehicleData").hide();
    // }
    //onChange(event.target.value)
}

     /////////////////////////////vehicle search/////////////////////////
     const searchVehicleList = (vehicleName) =>
     {
        $("#vehicleListBlock").show();
        var customerId="1";
        setSearchVehicle(vehicleName)
        customerVehicle(customerId, "1", ITEMS_PER_PAGE, vehicleName, "", ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setVehicleListDocs, setTotalRecord, setdisplayPage);

    }
    const handleChange_vhicle = (event,index,vinNumber,vehicleId) =>
    {
        setSearchVehicle(vinNumber)
        setVehicleId(vehicleId);
        $("#searchText").focus();
        $("#vehicleListBlock").hide();
        //onChange(event.target.value)
    }
    /////////////////vehicle pagination////////////////////////////
    const handleDocPageChange = (offset,triggerevent) =>
    {
        customer_doc_api(customerId,"1",ITEMS_PER_PAGE_DOCS,"",ITEMS_PER_PAGE_DOCS,setTotalDocsItems,settotalDocsPages,setCurrentDocsPage,setDocumentList,setTotalDocsRecord,setdisplayDocsPage,sortingField,sortingOrder,"");
    //  document_api("",offset,ITEMS_PER_PAGE1,"",ITEMS_PER_PAGE1,setTotalItems1,settotalPages1,setCurrentPage1,setDocumentList,setTotalRecord1,setdisplayPage1,"",sortingField,sortingOrder,"");

    }

    const backbuttonHandle = (tabName) => 
    {
       
      
        if (tabName === "fleetVehicle") {
            document.getElementById("pills-home-tab").click();
           sessionStorage.setItem("naviationfrom", "fleetVehicle");

        } if (tabName === "fleetDocument") {
            document.getElementById("pills-profile-tab").click();
           sessionStorage.setItem("naviationfrom", "fleetDocument");
        }
     }

     $(document).on("click", "#editVehicleN", function () {
        sessionStorage.setItem("naviationfrom", "fleetVehicle");

      });


      const accountType = () => 
      {
          var customerListReq =
          {
    
    
    
          };
    
          var requestOptions = requestObject.RequestHeader(customerListReq);
          services.getAccountType(requestOptions).then((res) => 
          {
           
              if (res.customValues) {
                  $("#fleetcompanyLogo").attr("src", "data:image/png;base64," + res.customValues.fileByte);
               }
              // if(res.customValues)
              // {
              //     try{
              //         sessionStorage.setItem("fleetCompanyLogo",res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/fleeto-icon.png" );
              //         $("#fleetCompanyLogo").attr("src","data:image/png;base64,"+res.customValues.fileByte);
              //     }
              //     catch(error)
              //     {
                  
  
              //     }
                 
              // }else{
              //     try
              //     {
              //         sessionStorage.setItem("fleetCompanyLogo", "/images/fleeto-icon.png" );
              //     }catch(error)
              //     {
                  
  
              //     }
                 
              // }
              sessionStorage.setItem("comapnyName",res.rows ? res.rows.name:"");
              $("#comapnyName").html(res.rows ? res.rows.name:"")
              // if(res.customValues)
              // {
                 
              //     try{
              //         alert("if"+res.customValues.fileByte)
              //         sessionStorage.setItem("fleetCompanyLogo",res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/fleeto-icon.png" );
              //         $("#fleetCompanyLogo").attr("src",res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/fleeto-icon.png");
              //         // $("#fleetCompanyLogo").attr("src","data:image/png;base64,"+res.customValues.fileByte);
              //     }
              //     catch(error)
              //     {
                  
  
              //     }
                 
              // }else{
              //     try
              //     {
              //         sessionStorage.setItem("fleetCompanyLogo", "/images/fleeto-icon.png" );
              //     }catch(error)
              //     {
                  
  
              //     }
                 
              // }
              sessionStorage.setItem("comapnyName",res.rows ? res.rows.name:"");
              $("#comapnyName").html(res.rows ? res.rows.name:"")
              if(res.customValues)
              {
                  if(res.customValues.fileByte==="")
                  {
                     
                      $("#fleetcompanyLogo").attr("src","/images/fleeto-icon.png");
                    
                  }else{
                      try{
                      sessionStorage.setItem("fleetCompanyLogo",res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/fleeto-icon.png" );
                      $("#fleetcompanyLogo").attr("src","data:image/png;base64,"+res.customValues.fileByte);
                      } catch(error)
                      {
                      
         
                      }
                  }
  
              }
    
             
    
  
    
          });
      }
  return(
  <>

   <div class="main-panel position-relative">
   {loadingComponent}
   {LoadingComponentForSubmit}
                <div class="content-wrapper" style={{display: "block"}}>
                    <div class="row">
                        <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                            <h4 class="font-weight-bold my-4 text-uppercase">Vehicles</h4>
                        </div>
                    </div>
                    <div class="row mb-2 ">
                        <div class="col-lg-11 col-md-12 m-auto ">
                            <div>
                                <ul class="nav nav-pills tab-custome" id="pills-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="pills-home-tab" onClick={() => backbuttonHandle('fleetVehicle')} data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Vehicles</a>
                                    </li>
                                   <li class="nav-item">
                                        <a class="nav-link" id="pills-profile-tab" onClick={() => backbuttonHandle('fleetDocument')} data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Documents</a>
                                    </li>
                                    
                                </ul>
                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                        <div class="row mb-1">
                                            <div class="col-md-12">
                                                <div class="card">
                                                    <div class="d-flex w-100  separatedSearchBox flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                                                        <div class="d-flex w-100 ">
                                                            <div class="search position-relative align-self-start mb-1 mt-1 w-100">
                                                                {/* <input type="search" id="gsearch" name="gsearch" placeholder="Search by vehicle number" class="pl-5"/>
                                                                <a href="#" class="search-icon">
                                                                    <img src="/images/icons/search.svg" alt=""/>
                                                                </a> */}
                                                                 <Search
                                                            placeholderName="Search by Unit No. or VIN"
                                                            onSearch={value => {
                                                                searchList(value);
                                                            }}

                                                            />
                                                <a href="#" class="search-icon">
                                                                    <img src="/images/icons/search.svg" alt=""/>
                                                                </a>
                                                            </div>
                                                            {/* <div class="d-flex sort align-self-start mt-1 mb-1">
                                                                <div class="d-flex align-items-center align-self-start">
                                                                    <span class="mr-2">Vehicle Type :</span>
                                                                    <div class="dropdown show mr-3">
                                                                        <a class="btn btn-outline-info dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Select</a>
                                                                        <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                                            <a class="dropdown-item" href="#">Tractor</a>
                                                                            <a class="dropdown-item" href="#">Truck</a>
                                                                            <a class="dropdown-item" href="#">Trailer</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                        {/* <div class="d-flex align-self-start mb-1 mt-1">

                                                        {roles.match("Driver")||roles.match("Dispatcher")?"":<a  onClick={() => redirect("/fleetowner/AddVehicle")} type="button" class="btn btn-success">New Vehicle</a>}

                                                           
                                                        </div> */}
                                                    </div>
                                                    <div class="card-body pt-0 pb-0">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="table-responsive driver-table">
                                                                    <table class="table ind-record-wrap">
                                                                    {/* <Tableheader
                                                                                            headers={headers}

                                                                    /> */}
                                                                    <tr class="d-none">
                                                                    {headers.map(({ name, field, sortable,thStyle,inlineStyle }) => (
                                                                                                <th
                                                                                                    key={name}
                                                                                                    onClick={() =>
                                                                                                        sortable ? onSortingChangeVehicle(field) : null
                                                                                                    }
                                                                                                    class={thStyle} style={inlineStyle}>
                                                                                                    {name}

                                                                                                    {sortingField && sortingField === field && (

                                                                                                        sortingOrder === "asc"
                                                                                                            ? "↑"
                                                                                                            : "↓"
                                                                                                    )}

                                                                                                </th>
                                                                                            ))}
                                                                    </tr>
                                                                        {/* <thead>
                                                                            <tr>
                                                                                <th>Vehicle Number</th>
                                                                                <th>Vehicle Type </th>
                                                                                <th>VIN Number</th>
                                                                                <th>Driver</th>
                                                                                <th>Contact Number</th>
                                                                                <th>View</th>
                                                                                <th>Action</th>
                                                                    {"/fleetowner/AddVehicle/"+customerId}        </tr>
                                                                        </thead> */}

                                                                        <tbody>
                                                         {
                                                               vechileLists?vechileLists.map((data, index) => (
                                                            <tr>
                                                               {/* <td>{data.lisencePlateNumber?data.lisencePlateNumber:"--"}</td> */}
                                                                <td>
                                                                   
                                                                    <label for="">Unit Number</label>
                                                                    <span> {data.unitNumber?data.unitNumber:"--"}</span>
                                                                  
                                                                   
                                                                </td>
                                                               <td>
                                                                                   <label for="">VIN Number</label>
                                                                                    <span>{data.vinNumber?data.vinNumber:"--"}</span> 
                                                               </td>
                                                               <td>
                                                                                    <label for="">Type</label>
                                                                                    <span> {data.vehicleTypeName?data.vehicleTypeName:"--"}</span>
                                                                </td>
                                                               <td>
                                                                                    <label for="">Sub Type</label>
                                                                                    <span> {data.trailerSubTypeName?data.trailerSubTypeName:"--"}</span>
                                                                  
                                                                   
                                                               </td>
                                                               {/* <td>{data.make?data.make:"NA"} - {data.model?data.model:"NA"}</td> */}
                                                               {/* <td>{data.customerNumber?data.customerNumber:"--"}</td> */}
                                                               <td class="ml-auto border-0 w-100">
                                                                   
                                                                   <a onClick={() => redirect("/fleetowner/VehicleInformation/"+data.id)} class="btn btn-primary">View</a>&nbsp;&nbsp;

                                                               {/* {roles.match("Driver")||roles.match("Dispatcher")?"": <a  onClick={() => redirect("/fleetowner/FleetEditVehicle/"+data.id)} class="btn btn-outline-primary text-uppercase" id="editVehicleN">Edit</a>} */}

                                                                </td>
                                                             
                                                            </tr>
                                                               )):<div class="no-data center"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                                                               </div>
                                                         }

                            </tbody>
                                                                            {/* <tr>
                                                                                <td>ERS 8547</td>
                                                                                <td>Tractor</td>
                                                                                <td>IPMS44324T1017489</td>
                                                                                <td>James</td>
                                                                                <td>(617) 399 8424</td>
                                                                                <td><a href="/fleetowner/VehicleInformation" class="btn btn-outline-primary text-uppercase">View</a> </td>
                                                                                <td>
                                                                                    <div class="dropdown">
                                                                                        <a class="btn dropdown-toggle dropdown-actions" href="#" role="button" id="dropdownActions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                            <span class="dots"></span><span class="dots"></span><span class="dots"></span>
                                                                                        </a>
                                                                                        <div class="dropdown-menu noi" aria-labelledby="dropdownActions">
                                                                                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#assignDriver">Change Driver</a>
                                                                                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#assignDispatcher">Change Dispatcher</a>
                                                                                            <a class="dropdown-item" href="/fleetowner/EditVehicle">Edit Vehicle</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>ERS 8547</td>
                                                                                <td>Tractor</td>
                                                                                <td>IPMS44324T1017489</td>
                                                                                <td>James</td>
                                                                                <td>(617) 399 8424</td>
                                                                                <td><a href="/fleetowner/VehicleInformation" class="btn btn-outline-primary text-uppercase">View</a> </td>
                                                                                <td>
                                                                                    <div class="dropdown">
                                                                                        <a class="btn dropdown-toggle dropdown-actions" href="#" role="button" id="dropdownActions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                            <span class="dots"></span><span class="dots"></span><span class="dots"></span>
                                                                                        </a>
                                                                                        <div class="dropdown-menu noi" aria-labelledby="dropdownActions">
                                                                                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#assignDriver">Change Driver</a>
                                                                                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#assignDispatcher">Change Dispatcher</a>
                                                                                            <a class="dropdown-item" href="/fleetowner/EditVehicle">Edit Vehicle</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td>ERS 8547</td>
                                                                                <td>Tractor</td>
                                                                                <td>IPMS44324T1017489</td>
                                                                                <td><a href="#" class="btn btn-primary btn-sm text-uppercase" data-toggle="modal" data-target="#assignDriver">ASSIGN</a></td>
                                                                                <td>(617) 399 8424</td>
                                                                                <td><a href="/fleetowner/VehicleInformation" class="btn btn-outline-primary text-uppercase">View</a></td>
                                                                                <td>
                                                                                    <div class="dropdown show mr-3 ">
                                                                                        <a class="btn dropdown-toggle dropdown-actions" href="#" role="button" id="dropdownActions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                            <span class="dots"></span><span class="dots"></span><span class="dots"></span>
                                                                                        </a>
                                                                                        <div class="dropdown-menu noi" aria-labelledby="dropdownActions">
                                                                                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#assignDriver">Change Driver</a>
                                                                                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#assignDispatcher">Change Dispatcher</a>
                                                                                            <a class="dropdown-item" href="/fleetowner/EditVehicle">Edit Vehicle</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td>ERS 8547</td>
                                                                                <td>Tractor</td>
                                                                                <td>IPMS44324T1017489</td>
                                                                                <td><a href="#" class="btn btn-primary btn-sm text-uppercase">ASSIGN</a></td>
                                                                                <td>(617) 399 8424</td>
                                                                                <td><a href="/fleetowner/VehicleInformation" class="btn btn-outline-primary text-uppercase">View</a></td>
                                                                                <td>
                                                                                    <div class="dropdown show mr-3 ">
                                                                                        <a class="btn dropdown-toggle dropdown-actions" href="#" role="button" id="dropdownActions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                            <span class="dots"></span><span class="dots"></span><span class="dots"></span>
                                                                                        </a>
                                                                                        <div class="dropdown-menu noi" aria-labelledby="dropdownActions">
                                                                                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#assignDriver">Change Driver</a>
                                                                                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#assignDispatcher">Change Dispatcher</a>
                                                                                            <a class="dropdown-item" href="/fleetowner/EditVehicle">Edit Vehicle</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr> */}

                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center justify-content-between p-3">
                                                    <div class="d-flex">
                                                        <div class="dropdown show mr-3 ">
                                                        <select class="form-control custome-select btn gray-mid-color dropdown-toggle"  href="#" role="button" id="display_pages_in_items" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                        {
                                                                                                    displayPage.map((data, index) => (
                                                                                                        <option value={data}>{data}</option>
                                                                                                    ))
                                                                                        }
                                                                                        </select>
                                                        </div>
                                                        <span class="d-flex align-items-center pagenation-mob">{currentPage*ITEMS_PER_PAGE<=totalRecors?currentPage*ITEMS_PER_PAGE:totalRecors} out of {totalRecors} Records Displayed</span>
                                                    </div>
                                                        <nav aria-label="Page navigation example">
                                                            <Pagination
                                                                total={totalRecors}
                                                                itemsPerPage={ITEMS_PER_PAGE}
                                                                currentPage={currentPage}
                                                                //onPageChange={page => setCurrentPage(page)}
                                                                onPageChange={handlePageChange}
                                                            />

                                                        </nav>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <div class="row mb-1">
                                            <div class="col-md-12">
                                                <div class="card">
                                                    <div class="d-flex flex-column separatedSearchBox flex-md-row justify-content-md-between justify-content-start align-items-center p-3 w-100">
                                                        <div class="d-flex w-100">
                                                            <div class="search position-relative align-self-start mb-1 mt-1 w-100">
                                                            <Search placeholderName = "Search by Unit No. or Title or Type"
                                                            onSearch =
                                                            {
                                                                value => {
                                                                    searchListForDocs(value);
                                                                }
                                                            }

                                                            />
                                                                <a href="#" class="search-icon">
                                                                    <img src="/images/icons/search.svg" alt=""/>
                                                                </a>
                                                            </div>
                                                            {/* <div class="d-flex sort align-self-start mt-1 mb-1 mr-2">
                                                                <div class="d-flex align-items-center align-self-start">
                                                                    <span class="mr-2 mb">Vehicle Type :</span>
                                                                    <div class="dropdown show mr-3">
                                                                        <a class="btn btn-outline-info dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Select</a>
                                                                        <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                                            <a class="dropdown-item" href="#">Tractor</a>
                                                                            <a class="dropdown-item" href="#">Truck</a>
                                                                            <a class="dropdown-item" href="#">Trailer</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                            {/* <div class="d-flex sort align-self-start mt-1 mb-1">
                                                                <div class="d-flex align-items-center align-self-start">
                                                                    <span class="mr-2">Doc Type :</span>
                                                                    <div class="dropdown show mr-3">
                                                                        <a class="btn btn-outline-info dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Select</a>
                                                                        <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                                            <a class="dropdown-item" href="#">Inspection</a>
                                                                            <a class="dropdown-item" href="#">Insurance</a>
                                                                            <a class="dropdown-item" href="#">Tests</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                        {/* <div class="d-flex align-self-start mb-1 mt-1">

                                                        {roles.match("Driver")||roles.match("Dispatcher")?"":<a href="" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#adddocs">Add Document</a>}

                                                        </div> */}
                                                    </div>
                                                    <div class="row">
                                                                        <div class="col-lg-12 col-md-12 m-auto grid-margin stretch-card">
                                                                        <div class="card">
                                                                            {/* <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                                                                                <div class="search position-relative align-self-start mb-1 mt-1">


                                                                                    <Search
                                                                                                            placeholderName="Search by title/category/vehicle number"
                                                                                                            onSearch={value => {
                                                                                                                searchList(value);
                                                                                                            }}

                                                                                                            />

                                                                                <a href="#" class="search-icon">
                                                                                <img src="images/icons/search.svg" alt=""/>
                                                                                </a>
                                                                                </div>
                                                                                <div class="d-flex align-self-start mb-1 mt-1">
                                                                                    <a href="" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#adddocs">Add Document</a>
                                                                                </div>
                                                                            </div> */}
                                                                            <div class="card-body pt-0 pb-0">

                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                <div class="table-responsive driver-table">

                                                                                    <table class="table ind-record-wrap">
                                                                                    <tr class="d-none">
                                                                                            {headersDoc.map(({ name, field, sortable,thStyle,inlineStyle }) => (
                                                                                                <th
                                                                                                    key={name}
                                                                                                    onClick={() =>
                                                                                                        sortable ? onSortingChangeDocument(field) : null
                                                                                                    }
                                                                                                    class={thStyle} style={inlineStyle}>
                                                                                                    {name}

                                                                                                    {sortingField && sortingField === field && (

                                                                                                        sortingOrder === "asc"
                                                                                                            ? "↑"
                                                                                                            : "↓"
                                                                                                    )}

                                                                                                </th>
                                                                                            ))}
                                                                                        </tr>


                                                                                    <tbody>
                                                                                            {
                                                                                                                        documentList?documentList.map((data, index) => (
                                                                                                                        <tr>
                                                                                                                          
                                                                                                                            
                                                                                                                        {/* <td>{data.documentCategoryName?data.documentCategoryName:"--"} </td> */}
                                                                                                                        <td>
                                                                                                                        <label for="">Type</label>
                                                                                                                        <span>{data.documentSubCategoryName?data.documentSubCategoryName:"--"}</span> 
                                                                                                                          
                                                                                                                            
                                                                                                                            </td>
                           
                                                                                                                        <td >
                                                                                                                            
                                                                                                                          <label for="">Title</label>
                                                                                                                          <span>{data.title?data.title:"--"}</span> 
                                                                                                                        </td>
                                                                                                                        <td>

<label for="">Unit Number</label>
 <span>{data.unitNumber?data.unitNumber:"--"}</span> 

</td>
                                                                                                                        {/* <td style={{width:"100px", whiteSpace:"break-spaces", lineHeight:"18px"}}>{data.documentDate?moment(data.documentDate).format('MM-DD-YYYY'):"--"}</td> */}
                                                                                                                        <td >
                                                                                                                             <label for="">Expiry Date</label>
                                                                                                                             <span>{data.expiryDate?moment(data.expiryDate).format('MM-DD-YYYY'):"--"}</span> 
                                                                                                                        </td>
                                                                                                                        {/* <td>{data.createUser?data.createUser:"--"}</td> */}
                                                                                                                        <td class="w-100">  <a  onClick={() => document_details(data.id,data.filePath)} class="btn btn-primary">View</a>&nbsp;&nbsp;
                                                                                                                        {/* {roles.match(Owner)?<a onClick={() => deleteDocuments(data.id,data.unitNumber != '' ? data.unitNumber : '',data.title != '' ? data.title : '')} class="btn btn-outline-primary text-uppercase mr-2">Delete</a>:""}
                                                                                                                        {data.documentCategoryName === "Inspection Documents" ? "" :roles.match("Driver")||roles.match("Dispatcher")?"":<a   onClick={() => redirect("/fleetowner/EditDocument/"+ sessionStorage.getItem("userId") + "/" + data.fkVehicleId + "/" + data.id)} class="btn btn-outline-primary text-uppercase">Edit</a>} */}

                                                                                                                       
                                                                                                                        </td>
                                                                                                                        {/* <td style={{ width: "50px" }}><a href={"/fleetowner/EditDocument/"+ sessionStorage.getItem("userId") + "/" + data.fkVehicleId + "/" + data.id} class="btn btn-outline-primary text-uppercase">Edit</a></td> */}
                                                                                                                        {/* <td style={{"width":"50px"}}><a class="btn btn-outline-primary text-uppercase"  onClick={() => documentDetails_(data.id)}>View</a></td> */}
                                                                                                                        </tr>
                                                                                                                        )):<div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                                                                                                                        </div>
                                                                                            }

                                                                                        </tbody>
                                                                                </table>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            </div>

                                                                            <div class="d-flex align-items-center justify-content-between p-3">
                                                                            <div class="d-flex">
                                                                                <div class="dropdown show mr-3 ">
                                                                                <select class="form-control custome-select btn gray-mid-color dropdown-toggle"  href="#" role="button" id="display_pages_in_items1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                                                {
                                                                                                                            displayDocsPage.map((data, index) => (
                                                                                                                                <option value={data}>{data}</option>
                                                                                                                            ))
                                                                                                                }
                                                                                                                </select>
                                                                                </div>
                                                                                <span class="d-flex align-items-center pagenation-mob">{currentDocsPage*ITEMS_PER_PAGE_DOCS<=totalDocsItems?currentDocsPage*ITEMS_PER_PAGE_DOCS:totalDocsItems} out of {totalDocsItems} Records Displayed</span>
                                                                            </div>
                                                                                <nav aria-label="Page navigation example">
                                                                                <Pagination
                                                                                    total={totalDocsItems}
                                                                                    itemsPerPage={ITEMS_PER_PAGE_DOCS}
                                                                                    currentPage={currentDocsPage}
                                                                                    //onPageChange={page => setCurrentPage(page)}
                                                                                    onPageChange={handlePageChangedocument}
                                                                                />

                                                                                </nav>
                                                                            </div>

                                                                            </div>

                                                                        </div>

                                                                        </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Modal --> */}
                <div class="modal fade" id="assignDriver" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Assign to Driver</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                           </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="search position-relative w-100">
                                            <input type="search" id="gsearch" name="gsearch" placeholder="Search driver" class="pl-5 w-100"/>
                                            {/* <a href="#" class="search-icon"> <img src="/images/icons/search.svg" alt=""/></a> */}
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2">
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                            <label class="form-check-label" for="flexRadioDefault1">Noah Oliver</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
                                            <label class="form-check-label" for="flexRadioDefault2">Benjamin Roads</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3"/>
                                            <label class="form-check-label" for="flexRadioDefault3">Luke Asther</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4"/>
                                            <label class="form-check-label" for="flexRadioDefault4">Noah Oliver</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5"/>
                                            <label class="form-check-label" for="flexRadioDefault5">Benjamin Roads</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault6"/>
                                            <label class="form-check-label" for="flexRadioDefault6">Luke Asther</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="adddocs" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Add Document</h5>
                                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                   <span aria-hidden="true" >×</span>
                                </button>
                            </div>
                            <div class="modal-body scroll-auto">


                                <div class="row mb-2" id="vehicleData">
                                    <div class="col-12">
                                        <div class="search position-relative w-100">
                                          {/* <Search
                                                    placeholderName="VIN Number"
                                                    onSearch={value => {
                                                      searchVehicleList(value);
                                                    }}

                                          /> */}
                                           <input type="text" id="searchText" value={searchVehicle} placeholder="Search with VIN number" className="form-control searchValues" onChange={e => {
                      searchVehicleList(e.target.value);
                    }} />
                                          <a href="#" class="search-icon"> <img src="/images/icons/search.svg" alt="" /></a>
                                            {/* <input type="search" id="gsearch" name="gsearch" placeholder="Search driver" class="pl-5 w-100" />
                                            <a href="#" class="search-icon"> <img src="images/icons/search.svg" alt="" /></a> */}
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2 px-3" id="vehicleListBlock">
                                    {
                                            vehicleListDocs?vehicleListDocs.map((data, index) => (
                                        <div class="form-check input-align-right align-items-center d-flex w-100  mt-3 mb-3" onClick={(e)=>handleChange_vhicle(e,index,data.vinNumber,data.id) }>
                                            <input class="form-check-input" type="hidden" name="flexRadioDefault" value={data.id} id="flexRadioDefault1" />
                                            <div class="d-flex align-items-center">
                                                <div class="add-driver-logo">
                                                    <img src="../images/driver.png" alt="" />
                                                </div>
                                                <label class="form-check-label" for="flexRadioDefault1"><b>{data.vinNumber}</b><i class="input-helper"></i></label>
                                            </div>
                                        </div>
                                        )):"no record"
                                     }



                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <a class="btn btn-primary" data-bs-dismiss="modal" onClick={() => redirect("/fleetowner/AddDocument/"+vehicleId)}>Select Vehicle</a>
                                {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="assignDispatcher" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Assign to Dispatcher</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                           </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="search position-relative w-100">
                                            <input type="search" id="gsearch" name="gsearch" placeholder="Search Dispatcher" class="pl-5 w-100"/>
                                            <a href="#" class="search-icon"> <img src="/images/icons/search.svg" alt=""/></a>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2">
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                            <label class="form-check-label" for="flexRadioDefault1">Noah Oliver</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
                                            <label class="form-check-label" for="flexRadioDefault2">Benjamin Roads</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3"/>
                                            <label class="form-check-label" for="flexRadioDefault3">Luke Asther</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4"/>
                                            <label class="form-check-label" for="flexRadioDefault4">Noah Oliver</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5"/>
                                            <label class="form-check-label" for="flexRadioDefault5">Benjamin Roads</label>
                                        </div>
                                        <div class="form-check input-align-right w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault6"/>
                                            <label class="form-check-label" for="flexRadioDefault6">Luke Asther</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="addVehicle" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Add Vehicle</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                   <span aria-hidden="true" >×</span>
                                </button>
                            </div>
                            <div class="modal-body scroll-auto">
                                <div class="row mb-4">
                                    <div class="col-12">
                                        <div class="search position-relative w-100">
                                        <Search
                                                   placeholderName="customer name"
                                                   onSearch={value => {
                                                    searchCustomerList(value);
                                                   }}

                                                />
                                                {/* <a href="#" class="search-icon"> <img src="images/icons/search.svg" alt="" /></a> */}
                                            {/* <input type="search" id="gsearch" name="gsearch" placeholder="Search driver" class="pl-5 w-100" />
                                            <a href="#" class="search-icon"> <img src="images/icons/search.svg" alt="" /></a> */}
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2 px-3">
                                    {
                                            customerList?customerList.map((data, index) => (
                                        <div class="form-check input-align-right align-items-center d-flex w-100  mt-3 mb-3">
                                            <input class="form-check-input" type="radio" name="customerId" value={data.customerId} id="flexRadioDefault1"  onChange={ handleChange } />

                                            <div class="d-flex align-items-center">
                                                <div class="add-owner-logo">
                                                    <img src="../images/transport-logo.jpg" alt="" />
                                                </div>



                                               <label class="form-check-label" for="flexRadioDefault1">  <b>{data.customerName}</b> </label>


                                            </div>
                                        </div>)):"no record"
                                     }


                                    </div>
                                </div>


                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <a onClick={() => redirect("/fleetowner/AddVehicle/"+customerId)}  >Add</a>
                                {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                            </div>
                        </div>
                    </div>
                </div>
  </>
)};

FleetVehicle.propTypes = {};

FleetVehicle.defaultProps = {};

export default FleetVehicle;
