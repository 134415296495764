import services from "../services/services";
import { requestObject } from "./requestObject";
import Swal from "sweetalert2";
import { FileOpener } from '@capawesome-team/capacitor-file-opener';
import $ from "jquery";
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import write_blob from "capacitor-blob-writer";
import { saveAs } from 'file-saver';
import { AddAlertOutlined } from "@material-ui/icons";
// import { Button, Linking, StyleSheet,NativeModules,Platform, PermissionsAndroid,View } from "react-native";

// const { DefaultBrowserModule } = NativeModules;


// function dataURItoBlob(dataURI) {
//    const byteString = window.atob(dataURI);
//    const arrayBuffer = new ArrayBuffer(byteString.length);
//    const int8Array = new Uint8Array(arrayBuffer);
//    for (let i = 0; i < byteString.length; i++) {
//      int8Array[i] = byteString.charCodeAt(i);
//    }
//    const blob = new Blob([int8Array], { type: 'application/pdf'});
//    return blob;
//  }
// export const CheckFilePermissions = async (platform) => {
//    if(platform === 'android') {
//      try {
//        const granted = await PermissionsAndroid.requestMultiple([
//          PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
//          PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
//        ]);
//        if (granted['android.permission.READ_EXTERNAL_STORAGE'] && granted['android.permission.WRITE_EXTERNAL_STORAGE']) {
//          // user granted permissions
//          return true;
//        } else {
//          // user didn't grant permission... handle with toastr, popup, something...
//          return false;
//        }
//      } catch (err) {
//        // unexpected error
//        return false;
//      }
//    } else {
//      // platform is iOS
//      return true;
//    }
//  };
// export const openUrls = async (url) => 
// {
//    if (Platform.OS === 'android') 
//    {
//       if(CheckFilePermissions(Platform.OS))
//       {
//          DefaultBrowserModule.openUrl(url); 
//       }

//    }else 
//    {
//      await Linking.openURL(url);
//    }
//  };
const writeSecretFile = async (base64) => {
   await Filesystem.writeFile({
       path: "filename.pdf",
       data: 'application/pdf;base64 '+base64,
       directory: Directory.ExternalStorage,
       encoding: Encoding.UTF8
     });
  };
export const document_details = (id, filePath) => {

    var extension = filePath.match('[^.]+$');

      var document_request =
      {

         "id": id

      };

      var requestOptions = requestObject.RequestHeader(document_request);
      $("#loadingforsubmit").show();
      services.getDocumentDetails(requestOptions).then((res) => {
         $("#loadingforsubmit").hide();
         if (res.customValues.fileByte === "") {

            Swal.fire({
               position: 'center',
               icon: 'error',
               title: "",
               text: "File is not attached",

               showConfirmButton: true

            }).then((result) => {
               if (result.isConfirmed) {

               }
            });
         } else {

            var file;

            if (extension == "pdf") 
            {

            //    var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
            //    var byteNumbers = new Array(byteCharacters.length);
            //    for (var i = 0; i < byteCharacters.length; i++) {
            //       byteNumbers[i] = byteCharacters.charCodeAt(i);
            //    }
            //    var byteArray = new Uint8Array(byteNumbers);
            //    file = new Blob([byteArray], { type: 'application/pdf;base64' });
            //    var fileURL = URL.createObjectURL(file);

            //    var windowSize = "width=" + window.innerWidth + ",height=" + window.innerHeight + ",scrollbars=no";
            //   // writeSecretFile(res.customValues ? res.customValues.fileByte : "")
            //   var url = "http://docs.google.com/gview?embedded=true&url=" + res.customValues ? res.customValues.fileByte : "";
              if(window.ReactNativeWebView) 
              {     
                 window.ReactNativeWebView.postMessage(JSON.stringify(res.customValues ? res.customValues.fileByte+"_"+"pdf" : ""))
              }
            //  window.open("http://docs.google.com/gview?embedded=true&url=" + res.customValues ? res.customValues.fileByte : "")
            //   write_blob({

            //    // The 'path' option should be a string describing where to write the file. It
            //    // may be specified as an absolute URL (beginning with "file://") or a relative
            //    // path, in which case it is assumed to be relative to the 'directory' option.
               
            //        path: 'Downloads/'+fileURL,
               
            //    // The 'directory' option is used to resolve 'path' to a location on the disk.
            //    // It is ignored if the 'path' option begins with "file://".
               
            //        directory: Directory.Documents,
               
            //    // The 'blob' option must be a Blob, which will be written to the file. The file
            //    // on disk is overwritten, not appended to.
               
            //        blob: file,
               
            //    // Fast mode vastly improves read and write speeds on the web platform. For
            //    // files written with 'fast_mode' set to true, Filesystem.readFile will produce
            //    // a Blob rather than a Base64-encoded string. The 'fast_mode' option is
            //    // ignored on iOS and Android. For backwards compatibility, it defaults to
            //    // false.
               
            //        fast_mode: true,
               
            //    // If the 'recursive' option is 'true', intermediate directories will be created
            //    // as required. It defaults to 'false' if not specified.
               
            //        recursive: true,
               
            //    // If 'write_blob' falls back to its alternative strategy on failure, the
            //    // 'on_fallback' function will be called with the underlying error. This can be
            //    // useful to diagnose slow writes. It is optional.
               
            //    // See the "Fallback mode" section below for a detailed explanation.
               
            //        on_fallback(error) 
            //        {
            //            alert(error);
            //        }
            //    }).then(function(savedFile) {
            //       //  alert("Video written."+results);
            //       Filesystem.getUri({
            //          path: 'Downloads/'+fileURL,
            //          directory: Directory.Documents
            //      }).then(function ({uri}) {
            //          alert(uri)
            //          saveAs(uri,"first.pdf")
            //      });
            //    });
               // const blob = dataURItoBlob(res.customValues ? res.customValues.fileByte : "");
               // const url = URL.createObjectURL(blob);
               // alert(url)
               // to open the PDF in a new window
             //  window.open(url, '_system');
               // window.open(url+"#Intent;scheme=http;package=com.android.chrome;end");
               // window.location = 'intent:'+url+'#Intent;end';
               // openUrls(fileURL);
               // window.replace(fileURL);
              // Linking.openURL(fileURL);
             //  window.open(fileURL);
            }
            else if (extension == "jpg") {
               // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
               // var image = new Image();
               // image.src = "data:image/jpg;base64," + res.customValues.fileByte;
              // var windowSize = "width=" + window.innerWidth + ",height=" + window.innerHeight + ",scrollbars=no";
               
               // var w = window.open("", 'popup', windowSize);
               // w.document.write(image.outerHTML);
               if(window.ReactNativeWebView) {     
                  window.ReactNativeWebView.postMessage(JSON.stringify(res.customValues ? res.customValues.fileByte+"_"+"jpg" : ""))
               }
            }
            else if (extension == "png") {
               // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
               if(window.ReactNativeWebView) {     
                  window.ReactNativeWebView.postMessage(JSON.stringify(res.customValues ? res.customValues.fileByte+"_"+"png" : ""))
               }
               // var image = new Image();
               // image.src = "data:image/png;base64," + res.customValues.fileByte;

               // var w = window.open("", 'popup', windowSize);
               // w.document.write(image.outerHTML);
            }
            else if (extension == "bmp") {
               // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
               var image = new Image();
               image.src = "data:image/bmp;base64," + res.customValues.fileByte;

               // var w = window.open("", 'popup', windowSize);
               // w.document.write(image.outerHTML);
            }
            else if (extension == "word") {

               var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
               var byteNumbers = new Array(byteCharacters.length);
               for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
               }
               var byteArray = new Uint8Array(byteNumbers);
               file = new Blob([byteArray], { type: 'application/msword;base64' });
               var fileURL = URL.createObjectURL(file);
               window.open(fileURL);
            }
            else if (extension == "docx" || extension == "doc" || extension == "docm" || extension == "dotx" || extension == "dotm") {

               var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
               var byteNumbers = new Array(byteCharacters.length);
               for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
               }
               var byteArray = new Uint8Array(byteNumbers);
               file = new Blob([byteArray], { type: 'application/msword;base64' });
               var fileURL = URL.createObjectURL(file);
               window.open(fileURL);
            }
         }

      });


}