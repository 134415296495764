import React, { useState } from "react";

const Search = ({ onSearch,placeholderName}) => {

    const [search, setSearch] = useState("");

    

    const onInputChange = value => {
     
        setSearch(value);
        onSearch(value);
        sessionStorage.setItem("searchValue",value);
    };
    return (
        
        // <input
        //     type="text"
        //     className="form-control"
        //     style={{ width: "240px" }}
        //     placeholder="Search"
        //     value={search}
        //     onChange={e => onInputChange(e.target.value)}
        // />
        <div class=" search_input position-relative w-100">
                                    <input type="text" id="searchValue"  value={search} placeholder={placeholderName} className="form-control searchValues w-100" onChange={e => onInputChange(e.target.value)} />
                                    {/* <a href="#" class="search-icon">
                                                                    <img src="/images/icons/search.svg" alt=""/>
                                                                </a> */}
                                    <button class="btn search-btn-pos-abs" type="submit">
                                        {/* <svg viewBox="0 0 24 24"><path d="M23.36,22,18,16.55A9.78,9.78,0,1,0,16.55,18L22,23.36a1,1,0,0,0,1.41,0A1,1,0,0,0,23.36,22ZM10.41,18.16a7.75,7.75,0,1,1,7.75-7.75A7.76,7.76,0,0,1,10.41,18.16Z"></path></svg> */}
                                        </button>
                                </div>
    );
};

export default Search;
