// import React,{history,useEffect, useState, useMemo } from "react";
// import {useNavigate , useParams } from "react-router-dom";
// import { keycloak } from "./config";
var token=localStorage.getItem("accToken");
var validate=false;
if(token !==null) 
{
   validate=true;

}else{
 
}
const CheckAuthentication=validate;
export default CheckAuthentication;