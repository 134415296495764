import React, { history, useEffect, useState, useMemo } from "react";
import { useKeycloak } from '@react-keycloak/web'
import { useNavigate, useParams } from "react-router-dom";
import CheckAuthentication from "../../utility/checkAuthentication";
import { keycloak } from "../../utility/config";
import $ from "jquery";
const AppBar = (props) => {

   const [pathName, setPathName] = useState([]);
   const [activePath, setActive] = useState('garage');
   const [roles, setRole] = useState("");
   const navigate = useNavigate();
  // const { keycloak } = useKeycloak();



   //  setRole(role);




   useEffect(() =>
   {


    
   
   //  setActive(window.location.pathname.split('/')[1]);

     if(CheckAuthentication)
      {
         var pathArray = window.location.pathname.split('/');
         var secondLevelLocation = pathArray[1];

         setPathName(secondLevelLocation);

         setRole(localStorage.getItem("Userroles"));

      }else
      {
         sessionStorage.clear();
         localStorage.clear();
        //navigate("/login");
        $("#sidebar").hide();
          window.location.href="/login";
      }



   }, []);
   // const handleLogInOut = () => {
   //    if (keycloak.authenticated) {
   //      props.history.push('/login')
   //      keycloak.logout()
   //    } else {
   //      keycloak.login()
   //    }
   //  }
  const close_btn=()=>
  {
   $('.sidebar').removeClass('active');
  }
   function getBtnClassName(path) {

      // if (!keycloak.authenticated) {
      //    try {

      //       // keycloak.login();
      //    } catch (Exception) {

      //    }

      // } else {
      //    // keycloak.login();
      //    keycloak.logout()
      // }


      // if (window.location.pathname.match(path)) {

      //    return 'nav-link active';
      // } else {
      //    return 'nav-link';
      // }



      // if(CheckAuthentication)
      // {

      // }else{
      //    navigate("/login");
      // }

   }
   const redirect = (page,from) => 
   {
   
   
      if(from==="Documents")
      {
       
         sessionStorage.setItem("naviationfrom","fleetDocument");
         window.location.replace(page)

      }
      else if(from==="vehicles")
      {
         sessionStorage.setItem("naviationfrom","");
         // navigate(page);
         window.location.replace(page)
      }
      else
      {
         window.location.replace(page)
      }


   }
   let timer = 0;
   let delay = 1000;
   let prevent = false;
   const logout = () => {
      keycloak.init({
        initOptions: {
  
          pkceMethod: 'S256',
          // must match to the configured value in keycloak
  
          // this will solved the error
          checkLoginIframe: false
        }
      }).success(function (authenticated) {
      });
      timer = setTimeout(function () {
        if (!prevent) {
  
          keycloak.init({
            initOptions: {
  
              pkceMethod: 'S256',
              // must match to the configured value in keycloak
  
              // this will solved the error
              checkLoginIframe: false
            }}).success(function(authenticated)
            {
              // keycloak.logout({"redirectUri":"https://www.dotstow.com/login"});
              //  keycloak.logout({"redirectUri":"http://dotstow-mobile.s3-website.us-east-2.amazonaws.com/login"});
               // keycloak.logout({"redirectUri":"http://dotstow-mobile.s3-website.us-east-2.amazonaws.com/login"});
              //  keycloak.logout({"redirectUri":"http://dotstow-dev.s3-website.us-east-2.amazonaws.com/login"});
              //for development
             // http://dotstow-dev.s3-website.us-east-2.amazonaws.com/login
              // keycloak.logout({"redirectUri":"http://localhost:3000/login"});
               // for production
                // keycloak.logout({"redirectUri":"http://dotstow-uat.s3-website.us-east-2.amazonaws.com/login"});
                sessionStorage.removeItem("comapnyName");
                sessionStorage.removeItem("fkAccountId");
                sessionStorage.removeItem("cmpnylogo");
                sessionStorage.removeItem("userId");
                sessionStorage.removeItem("customerName");
                sessionStorage.removeItem("email");
                sessionStorage.removeItem("accountType");
                sessionStorage.removeItem("userProfilePic");
                sessionStorage.removeItem("loggedinUser");
  
                localStorage.clear();
                // navigate("/");
              //  window.location.href="/";
                sessionStorage.removeItem('key');
                keycloak.logout({"redirectUri":"https://mobile.dotstow.com/login"});
  
            }).error(function() {
  
            });
              }
              prevent = false;
            }, delay);
  
  
  
    }
   
   return (

      <>
         <nav class="sidebar sidebar-offcanvas " id="sidebar">
            <ul class="nav position-fixed">
{/* 
           {(() => {
                  if (pathName != 'fleetowner')
                  {
                     return (
                        <>
                           <li class="nav-item">
                              <a class="nav-link active" id="dashboard"  className={getBtnClassName('/garage/dashboard')} onClick={() => redirect("/garage/dashboard")}>
                                 <i><img src="/images/icons/home-icon.svg" alt="" /></i>
                                 <span class="menu-title">Dashboard</span>
                              </a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link" id="customer" className={getBtnClassName('/customer')} onClick={() => redirect("/customer")}>
                                 <i><img src="/images/icons/customer-icon.svg" alt="" /></i>
                                 <span class="menu-title">Customers</span>
                              </a>
                           </li>

                           <li class="nav-item">
                              <a class="nav-link" id="vehicles" className={getBtnClassName('/vehicles')} onClick={() => redirect("/vehicles")}>
                                 <i><img src="/images/icons/vehicle-icon.svg" alt="" /></i>
                                 <span class="menu-title">Vehicles</span>
                              </a>
                           </li>
                        </>
                     )
                  }
               })()} */}


               {(() =>
               {
                  if (pathName === 'fleetowner')
                  {

                     return (
                        <>
                          {/* {roles.match("Driver")?"": <li class="nav-item">
                              <a class="nav-link" id="Fdashboard" className={getBtnClassName('/fleetowner/dashboard')} onClick={() => redirect("/fleetowner/dashboard")}>
                                 <i><img src="/images/icons/home-icon.svg" alt="" /></i>
                                 <span class="menu-title">Dashboard</span>
                              </a>
                           </li>}
                           {roles.match("Driver")?"":
                           <li class="nav-item">
                                          <a class="nav-link" id="Freminder" className={getBtnClassName('/fleetowner/reminder')} onClick={() => redirect('/fleetowner/reminder')}>
                                             <i><img src="/images/icons/document-icon.svg" alt="" /></i>
                                             <span class="menu-title">Reminders</span>
                                          </a>
                          </li>} */}
                          {/* <div onClick={() => close_btn()}>
                             <img src="/images/close.png" style={{width:"30px",height:"30px",float: "right"}}  />
                             </div> */}
                           <li class="nav-item">
                              <a class="nav-link" id="FVehicle" className={getBtnClassName('/fleetowner/Vehicle')} onClick={() => redirect("/fleetowner/Vehicle","vehicles")}>
                                 <i><img src="/images/icons/vehicle-icon.svg" alt="" /></i>
                                 <span class="menu-title">Vehicles</span>
                              </a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link" id="settings" className={getBtnClassName('/fleetowner/Vehicle')} onClick={() => redirect("/fleetowner/Vehicle","Documents")}>
                                 <i><img src="/images/icons/document-icon.svg" alt="" /></i>
                                 <span class="menu-title">Documents</span>
                              </a>
                           </li>
                           {/* {roles.match("Driver")?"":<li class="nav-item">
                                          <a class="nav-link" id="Femployees" className={getBtnClassName('/fleetowner/employees')} onClick={() => redirect('/fleetowner/employees')}>
                                          <i><img src="/images/icons/users-icon.svg" alt="" width={"50px"} /></i>
                                             <span class="menu-title">Users</span>
                                          </a>
                          </li>} */}


                           <li class="nav-item">
                              <a class="nav-link" id="Fprofile" className={getBtnClassName('/fleetowner/profile')} onClick={() => redirect('/fleetowner/profile',"")}>
                                 <i><img src="/images/icons/profile-icon.svg" alt="" /></i>
                                 <span class="menu-title">My Profile</span>
                              </a>
                           </li>

                           {/* {roles.match("Driver")||roles.match("Dispatcher")?"":<li class="nav-item">
                              <a class="nav-link" id="Fsettings" className={getBtnClassName('/fleetowner/settings')} onClick={() => redirect('/fleetowner/settings')}>
                                 <i><img src="/images/icons/setting-icon.svg" alt="" /></i>
                                 <span class="menu-title">Company Profile</span>
                              </a>
                           </li>} */}

                          <li class="nav-item">
                              <a class="nav-link" id="logout" className={getBtnClassName('/logout')} onClick={() => logout('')}>
                                 <i><img src="/images/icons/logout.svg" /></i>
                                 <span class="menu-title">Logout</span>
                              </a>
                           </li>


                        </>
                     )
                  }
               })()}


               {/* {(() => {
                  if (pathName != 'fleetowner') {
                     return (
                        <>
                           <li class="nav-item">
                              <a class="nav-link" id="documents" className={getBtnClassName('/documents')} onClick={() => redirect('/documents')}>
                                 <i><img src="/images/icons/document-icon.svg" alt="" /></i>
                                 <span class="menu-title">Documents</span>
                              </a>
                           </li>
                        
                           {roles.match("Owner")||roles.match("Manager")||roles.match("Supervisor")?<li class="nav-item">
                                          <a class="nav-link" id="users" className={getBtnClassName('/users')} onClick={() => redirect('/users')}>
                                          <i><img src="/images/icons/users-icon.svg" alt="" width={"50px"} /></i>
                                             <span class="menu-title">Users</span>
                                          </a>
                          </li>:""}
                          <li class="nav-item">
                              <a class="nav-link" id="profile" className={getBtnClassName('/profile')} onClick={() => redirect('/profile')}>
                                 <i><img src="/images/icons/profile-icon.svg" alt="" /></i>
                                 <span class="menu-title">My Profile</span>
                              </a>
                           </li>
                           {roles.match("Technician")||roles.match("Inspector")?"":<li class="nav-item">
                              <a class="nav-link" id="settings" className={getBtnClassName('/settings')} onClick={() => redirect('/settings')}>
                                 <i><img src="/images/icons/setting-icon.svg" alt="" /></i>
                                 <span class="menu-title">Company Profile</span>
                              </a>
                           </li> }
                        </>
                     )
                  }
               })()} */}


            </ul>
            <div class="d-flex flex-column text-center justify-content-center powerby">
               <span class="mb-3">Powered by</span>
               <img src="/images/dotstow-logo2.svg" alt="" class="m-auto mt-2" />
            </div>
         </nav>

      </>

   );
};

export default AppBar;