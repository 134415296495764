

export const requestObject = 
{
      RequestHeader,
     
}
function RequestHeader(requestedFromModule)
{
      const requestOptions = 
      {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 
         'Authorization': "Bearer "+localStorage.getItem("accToken")
      },
           
          body: JSON.stringify(requestedFromModule)
      };

      return requestOptions;
  
}