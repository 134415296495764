import React, { history, useEffect, useState, useMemo, useCallback } from "react";
import $, { parseJSON } from 'jquery';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import services from "../../../../services/services";
import "cropperjs/dist/cropper.css";

import { useLoading } from 'react-hook-loading';
import Swal from "sweetalert2";

import { requestObject } from "../../../../utility/requestObject";

import AdddocsDispatcher from "../../../../utility/AdddocsDispatcher";
import removePdf from "../../../../utility/removePdf";
import { LoadingComponentForSubmit } from "../../../../utility/loadingforsubmit";

const AddMyFleetdocs = () => {

    const { id } = useParams();
    var userId=id;
    const navigate = useNavigate();
    const [dispatcherDetails, setDispatcherDetails] = useState([]);
    var documentLists = [];
    const [loading, setLoading] = useLoading("Please wait...");
    const dummyImge = window.location.origin + "/images/nia.JPG";
    const [imgsrc, setImageSrc] = useState()
    const [image, setImage] = useState()

    const [cropper, setCropper] = useState()
    
    const [cropData, setCropData] = useState("")
    const [itemName, setFieldName] = useState('')

    const [croppedImage, setCroppedImage] = useState(null);
    const [fileName, setfileName] = useState("");

  
    const [issuedDate, setIssuedDate] = useState((new Date()));
    const [expDate, setExpDate] = useState((new Date()));
    const [inputList, setInputList] = useState([{}]);
   
    const [base64file, setBase64file] = useState('');

    const [documentList, setDocumentLists] = useState([]);

    const [roles, setListRoles] = useState([])
    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", defaultValues: { state: "hawai", role: "manager" },
    });

    //  mode: "onKeyup"


    useEffect(() => {
       
        dispatcherDetailsApi();
      
    }, []);

    const redirect=(page)=>
    {

        navigate(page);
        sessionStorage.setItem("naviationfrom","mydocs");
        // sessionStorage.setItem("naviationfrom","users");
    }
  
    const dispatcherDetailsApi = () => {

        var vechileReq =
        {
            "id": userId
        }

        var requestOptions = requestObject.RequestHeader(vechileReq);
        services.getUserDetails(requestOptions).then((res) => {

            setDispatcherDetails(res.rows);
            // alert(res.rows.deskNumber);
          
            if (res.rows) {
                try {

                    if (res.rows.documents) {

                        documentLists.push(res.rows.documents);
                        sessionStorage.setItem("documentList", JSON.stringify(res.rows.documents));
                        setDocumentLists(res.rows.documents ? res.rows.documents : "");

                        for (var i = 0; i < res.rows.documents.length; i++) {

                            if (res.rows.documents[i].name === "drivingLisence") {
                                $("#licenceNumber").val(res.rows.documents[i].documentNumber);

                                setIssuedDate(new Date(res.rows ? res.rows.documents[i].issuedDate : ""));
                                setExpDate(new Date(res.rows ? res.rows.documents[i].expiryDate : ""));
                                setFieldName(res.rows ? res.rows.documents[i].name : "");
                            }
                            else {

                                //   setfileNames(res.rows.documents[i].name);
                                //  $("#docName"+i).val(res.rows.documents[i].name);
                                //  $("#filename"+i).val(res.rows.documents[i].name);
                            }




                        }

                    }
                    else {

                    }

                } catch (ex) {

                }



            }
          
           try{
            setCropData(res.customValues.userImageFileByte ? "data:image/png;base64," + res.customValues.userImageFileByte : "/images/no-vehicle-image.png");
           }catch(error)
           {

           }
            //        setInputList(res.rows?res.rows.documents?res.rows.documents:"":"");
            
            // $("#vehicleImage").attr("src", res.customValues.userImageFileByte ? "data:image/png;base64," + res.customValues.userImageFileByte : "/images/transport-logo.jpg");
            
           // console.log("Dispatcher Details" + JSON.stringify(res.rows));
        });

    }


    // const onChange = (e) => {
    //     e.preventDefault();
    //     let files;
    //     if (e.dataTransfer) {
    //         files = e.dataTransfer.files;
    //     } else if (e.target) {
    //         files = e.target.files;
    //     }
    //     const reader = new FileReader();
    //     reader.onload = () => {
    //         setImage(reader.result);
    //     };
    //     reader.readAsDataURL(files[0]);
    // };
    const onChange = (e) => {
        e.preventDefault();
        let files;
         var ext =  e.target.value.split('.').pop();
          var allowedExtensions =/(\jpg|\png|\jpeg)$/i;
          
          if (!allowedExtensions.exec(ext)) 
          {
            
              Swal.fire({  
                position: 'center',  
                icon: 'error',  
                title: "",  
                text:"Invalid file type",
                showConfirmButton: true 
                
              }).then((result) => 
              {
                   
                    if(result.isConfirmed) 
                    {
                      
                      e.target.value = '';
                      return false;
                    }
              });
             
          }else{
             if (e.dataTransfer) {
                files = e.dataTransfer.files;
              } else if (e.target) {
                files = e.target.files;
              }
              const reader = new FileReader();
              reader.onload = () => {
                setImage(reader.result);
              };
              reader.readAsDataURL(files[0]);

          }
   
  };
    function handleChange(event) {
        setImageSrc(URL.createObjectURL(event.target.files[0]))

    }
  
  

    const document_details = (id, filePath) => {
        //  alert(id);
        var extension = filePath.match('[^.]+$');

        var document_request =
        {

            "id": id

        };

        var requestOptions = requestObject.RequestHeader(document_request);

        services.getUserDocumentDetails(requestOptions).then((res) => {

            if (res.customValues.fileByte === "") {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "",
                    text: "File is not attached",

                    showConfirmButton: true

                }).then((result) => {
                    if (result.isConfirmed) {

                    }
                });
            }
            else {

                var file;

                if (extension == "pdf") {
                    var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    file = new Blob([byteArray], { type: 'application/pdf;base64' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }
                else if (extension == "jpg") {
                    // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                    var image = new Image();
                    image.src = "data:image/jpg;base64," + res.customValues.fileByte;

                    var w = window.open("");
                    w.document.write(image.outerHTML);
                }
                else if (extension == "png") {
                    // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                    var image = new Image();
                    image.src = "data:image/png;base64," + res.customValues.fileByte;

                    var w = window.open("");
                    w.document.write(image.outerHTML);
                }
                //  else if(extension=="bmp")
                //  {
                //     var image = new Image();
                //     image.src = "data:image/bmp;base64,"+ res.customValues.fileByte;

                //     var w = window.open("");
                //     w.document.write(image.outerHTML);
                //  }
                //  else if(extension=="word")
                //  {

                //     var byteCharacters = atob(res.customValues?res.customValues.fileByte:"");
                //     var byteNumbers = new Array(byteCharacters.length);
                //     for (var i = 0; i < byteCharacters.length; i++) {
                //       byteNumbers[i] = byteCharacters.charCodeAt(i);
                //     }
                //     var byteArray = new Uint8Array(byteNumbers);
                //     file = new Blob([byteArray], { type: 'application/msword;base64' });
                //     var fileURL = URL.createObjectURL(file);
                //     window.open(fileURL);
                //  }
                //  else if(extension=="docx"||extension=="doc"||extension=="docm"||extension=="dotx"||extension=="dotm")
                //  {

                //     var byteCharacters = atob(res.customValues?res.customValues.fileByte:"");
                //     var byteNumbers = new Array(byteCharacters.length);
                //     for (var i = 0; i < byteCharacters.length; i++) {
                //       byteNumbers[i] = byteCharacters.charCodeAt(i);
                //     }
                //     var byteArray = new Uint8Array(byteNumbers);
                //     file = new Blob([byteArray], { type: 'application/msword;base64' });
                //     var fileURL = URL.createObjectURL(file);
                //     window.open(fileURL);
                //  }
            }

        });

    }

    // function validateState() {
    //     var isCheck = true;

    //     var checkedValues = $("input[name='category']:checked");
    //     var lenghtCheck=checkedValues.length;
    //     if (lenghtCheck==0) {
    //       isCheck = false;
    //       $("#categoryError").html("Category field is required");
    //     } else {
    //       isCheck = true;
    //       $("#categoryError").html("");
    //     }

    //     return isCheck;
    //   }

    const submitValue = () => {

        // var documentList = [];
        // try {
        //     for (var i = 0; i < inputList.length; i++) {
        //         var extension = "";
        //         var byteString = "";
        //         if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
        //             extension = "pdf";
        //             byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
        //         } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
        //             extension = "jpg";
        //             byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
        //         }
        //         else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
        //             extension = "png";
        //             byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
        //         }
        //         else {

        //         }


        //         documentList.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "extension": extension });
        //     }
        // } catch (exception) {
        //     documentList.push();

        // }

        try {

            for (var i = 0; i < inputList.length; i++) {

                var extension = "";
                var byteString = "";

                if (inputList[i].filetobase64.match("data:application/pdf;base64,")) {
                    extension = "pdf";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:application/pdf;base64,", "") : "";
                } else if (inputList[i].filetobase64.match("data:image/jpeg;base64,")) {
                    extension = "jpg";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/jpeg;base64,", "") : "";
                }
                else if (inputList[i].filetobase64.match("data:image/png;base64,")) {
                    extension = "png";
                    byteString = inputList[i].filetobase64 ? inputList[i].filetobase64.replace("data:image/png;base64,", "") : "";
                }
                else {

                }
                documentLists.push({ "byteString": byteString, "name": inputList[i].fileName.replace(/\.[^/.]+$/, ""), "title": $("#docName" + i).val(), "extension": extension });

            }
        } catch (exception) {
            documentLists.push();

        }
        try {
            if (JSON.parse(sessionStorage.getItem('documentList')).length > 0) {

                var i;
                var storedArray = JSON.parse(sessionStorage.getItem("documentList"));
                for (i = 0; i < storedArray.length; i++) {

                    documentLists.push({ "id": storedArray[i].id, "name": storedArray[i].name, "filePath": storedArray[i].filePath, "title": storedArray[i].name, "extension": storedArray[i].extension });
                }

            } else {

                 documentLists.push({"byteString":byteString,"name":inputList[i].fileName.replace(/\.[^/.]+$/, ""),"title": $("#docName"+i).val(),"extension":extension});
            }

        } catch (ex) {

        }
        // try {
        //     if (JSON.parse(sessionStorage.getItem('documentList')).length > 0) {

        //         var i;
        //         var storedArray = JSON.parse(sessionStorage.getItem("documentList"));
        //         for (i = 0; i < storedArray.length; i++) {

        //             documentLists.push({ "id": storedArray[i].id, "name": storedArray[i].name, "filePath": storedArray[i].filePath, "title": storedArray[i].name, "extension": storedArray[i].extension });
        //         }

        //     } else {

        //     }

        // } catch (ex) {

        // }

    //    setLoading(true);
        $("#loadingforsubmit").show();
        var vechileReq =
        {
            "id": userId,
          
           
            "documents": documentLists
        }

        var requestOptions = requestObject.RequestHeader(vechileReq);
        services.updateUser(requestOptions).then((res) => {
            // alert(res.message);
            // setLoading(false);
            $("#loadingforsubmit").hide();
            if (res.message === "Successfully updated user") {
                //   setLoading(false);
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: "",
                    text: res.message,
                    showConfirmButton: true
                }).then((result) => {

                    if (result.isConfirmed) {
                        // sessionStorage.setItem("naviationfrom","profile");
                        navigate("/fleetowner/profile");
                       
                    }
                })
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "",
                    text: res.message,
                    showConfirmButton: true
                }).then((result) => {

                    if (result.isConfirmed) {

                    }
                })
            }


        });
        // }

    }

    return (
        <>
        {LoadingComponentForSubmit}
            <div class="main-panel">
                <div class="content-wrapper" style={{ display: "block" }}>
                    <form onSubmit={handleSubmit(submitValue)}>
                        <div class="col-lg-9 col-md-12 m-auto ">
                            <div class="row">
                                <div class="col-md-12 m-auto mb-2 px-0 ">
                                    <h4 class="font-weight-bold my-4 text-uppercase">Add documents</h4>

                                   

                                  
                                    
                                    <div class="form-footer-comon-wrap">
                                        {/* <div class="row documents p-3">
                                            <div class="col-lg-12">
                                                {documentList.length > 0 ? <h4 class="mb-4">Documents</h4> : ""}

                                                {
                                                    documentList ? documentList.map((data, index) => (
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="d-flex align-items-center">

                                                                    <a onClick={() => document_details(data.id, data.filePath)} class="ml-2">
                                                  
                                                                        <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                                            <img src="" class="mr-2" />
                                                                            <span><p className="filename mb-0" style={{ cursor: "pointer", color: "#3f88c5" }}><img src="/images/icons/pdf-icon.svg" class="mr-2" ></img> {data.name ? data.name : "--"} </p></span>
                                                                            <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" /> </div>
                                                                    </a>
                                                                   
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) : ""

                                                }

                                            </div><br />

                                        </div> */}
                                        <div class="card mb-2 p-3">
                                            <h3 class="mb-4 pl-1">IDs & Other Documents</h3>
                                            <div class="col-md-6 text-left mb-3">
                                                <div class="instruction">
                                                    <span>Instruction</span>
                                                    <p>Max File Size : 50MB</p>
                                                    {/* <p>Formats: JPG/PNG/BMP</p> */}
                                                    <p>Formats: JPG/PNG/PDF</p>
                                                    <p>Ratio: 1:1 (width and height must be equal)</p>
                                                </div>
                                            </div>
                                            <div>
                                                {
                                                    documentList ? documentList.map((data, index) => (
                                                        <div class="col-md-12 px-0">
                                                            {data.name == "drivingLisence" ? "" :
                                                                <div class="align-items-center">

                                                                    {/* <a onClick={() => document_details(data.id, data.filePath)} class="ml-2"><b>{data.name}.{data.extension}</b></a> */}
                                                                    
                                                                    {/* {data.name}.{data.extension} */}
                                                                    <div class="doc-added-pdf mt-3 d-flex p-2 align-items-center">
                                                            <img src="" class="mr-2" />
                                                            <span style={{ width: "calc(100% - 50px)"}}><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>  */}
                                                                <a onClick={() => document_details(data.id, data.filePath)} class="ml-2 w-100">
                                                                {(() => {
                                                                            if (data.filePath.split(".")[1] == 'pdf') {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            } else if (data.filePath.split(".")[1] == 'jpg' || data.filePath.split(".")[1] == 'png' || data.filePath.split(".")[1] == 'jpeg' || data.filePath.split(".")[1] == 'bmp') {
                                                                                return (
                                                                                    <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            }else if (data.filePath.split(".")[1] == 'doc' || data.filePath.split(".")[1] == 'word' || data.filePath.split(".")[1] == 'docx' || data.filePath.split(".")[1] == 'dot' || data.filePath.split(".")[1] == 'dotm' || data.filePath.split(".")[1] == 'dotx') {
                                                                                return (
                                                                                    <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            }
                                                                        })()}
                                                                {data.name ? data.name : "--"} 
                                                                </a>
                                                                </p>
                                                                </span>
                                                                <img src="/images/icons/remove-minus.svg" onClick={() => removePdf(data.id, setDocumentLists)} alt="upload" />
                                                         </div>
                                                                    
                                                                    
                                                                </div>}
                                                        </div>


                                                    )) : ""

                                                }
                                            </div><br />
                                            <AdddocsDispatcher inputList={inputList} setInputList={setInputList} setBase64file={setBase64file} setfileName={setfileName} />
                                            {/* <table>
                                        <tbody>
                                            <tr>
                                                <th style={{width: "80%"}} class="p-2">Document Title</th>
                                                <th style={{width: "20%"}} class="p-2">Options</th>
                                            </tr>
                                            <tr>
                                                <td class="p-2">
                                                    <div class="form-group m-0">
                                                        <input type="email" class="form-control custome-input" placeholder="Document title 1"/>
                                                    </div>
                                                </td>
                                                <td class="p-2">
                                                    <div class="form-group m-0 d-flex">
                                                        <img src="/images/icons/pdf-icon.svg" class="pr-2"/>
                                                        <a href="#" class="mr-2"><img src="/images/icons/remove-minus.svg" alt="upload"/></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="p-2">
                                                    <div class="form-group m-0">
                                                        <input type="email" class="form-control custome-input" placeholder="Document title 1"/>
                                                    </div>
                                                </td>
                                                <td class="p-2">
                                                    <div class="form-group m-0 d-flex">
                                                        <a href="#" class="mr-2"><img src="/images/icons/upload.svg" alt=""/></a>
                                                        <a href="#"><img src="/images/icons/add-plus.svg" alt="upload"/></a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> */}
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="driver-pop-btn">
                                            <button type="submit" class="btn btn-primary ">Save changes</button>
                                            <button type="submit" class="btn gray-mid-color" onClick={() => redirect("/fleetowner/profile")}>Cancel</button>
                                            {/* <a onClick={() => redirect("/fleetowner/profile")}  class="btn gray-mid-color">Cancel</a> */}
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </form>





                </div>
                
            </div>

        </>
    )
};

AddMyFleetdocs.propTypes = {};

AddMyFleetdocs.defaultProps = {};

export default AddMyFleetdocs;
