import React,{history,useEffect, useState, useMemo } from "react";
import services from "../../../services/services";
import { requestObject } from "../../../utility/requestObject";
import Pagination from "../../../utility/pagination";
import {useNavigate , useParams } from "react-router-dom";
import $ from "jquery";
import CheckAuthentication from "../../../utility/checkAuthentication";
import moment from 'moment';
import { Driver } from "../../constant";
const FleetOwnerDashboard = () => 
{
    var roles = localStorage.getItem("Userroles");
    
    const[customerName,setCustomerName]=useState("");
    const[inspectionCuntType,setinspectionCountType]=useState("");
    const[dashboardAggregationData,setdashBoardAgreggation]=useState("");
    const[staffAndEmp,setstaff]=useState("");
    const[upcoming,setupcoming]=useState("");
    const[dues,setdues]=useState("");
    const [displayDocsPage, setdisplayDocsPage] = useState([]);
    const navigate = useNavigate();

    const [ITEMS_PER_PAGE, setitemperpage] = useState(25);
    const [ITEMS_PER_PAGE1, setitemperpage1] = useState(25);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalpages, settotalPages] = useState();
    const [displayPage, setdisplayPage] = useState([]);
    const [DashboardAggregation, setgetDashboardAggregation] = useState([]);
   
    // const [currentDocsPage, setCurrentDocsPage] = useState(5);
    // const [totaldocspages, settotalDocsPages] = useState();
    const [totalRecors,setTotalRecord] = useState([]);
    // const [totalDocsItems, setTotalDocsItems] = useState(0);
   
    const [ITEMS_PER_PAGE_DOCS, setitemperdocspage] = useState(25);
    useEffect(() => 
    {

        sessionStorage.removeItem("resolveAll");
        sessionStorage.removeItem("resolveSignleInspection");
        sessionStorage.removeItem("reminderDocumentId");
        
        if(CheckAuthentication)
        {
            var customerName= sessionStorage.getItem("customerName");
            setCustomerName(customerName);
    
            getTopTenReminders();
    
            FleetOwnerDashboardAgression();
            accountType();
            driverrole();
             if(localStorage.getItem("Userroles").match(Driver))
                {
                    navigate("/fleetowner/Vehicle");

                }
        }
        else
        {
            sessionStorage.clear();
            localStorage.clear();
           //navigate("/login");
             window.location.href="/login";
        }

    }, []); 

    const driverrole=()=>
    {
       var vechileReq=
        {
             
             
           
        }
    
        var requestOptions=requestObject.RequestHeader(vechileReq);
        services.DriverRole(requestOptions).then((res) => 
        {
            if(res.rows)
            {
                for(var i=0;i<res.rows.length;i++)
                {
                    if(res.rows[i].name==="Driver")
                    {
                        sessionStorage.setItem("driverRoleId",res.rows[i].id);
                        sessionStorage.setItem("driverRoleName",res.rows[i].name);
        
                    }


                }
           
            }
          
    
           
        });

    }
    const FleetOwnerDashboardAgression=()=>
    {
       var vechileReq=
        {
             
             
           
        }
    
        var requestOptions=requestObject.RequestHeader(vechileReq);
        services.getFleetDashBoardAggregations(requestOptions).then((res) => 
        {
          
          
            setgetDashboardAggregation(res.customValues?res.customValues:"");
    
           
        });

    }

    
    const vehicleInfo = (id,documentId,inspectionName) => {

        localStorage.setItem("vehicleId",id);
       // redirect("/fleetowner/VehicleInformation/" + data.id,'')
        navigate("/fleetowner/AssignToGarrage/" + id);

        sessionStorage.setItem("resolveSignleInspection", "true");
        sessionStorage.setItem("selectedinspectionName",inspectionName);
        sessionStorage.setItem("reminderDocumentId", documentId);
    }
   
    const redirect=(page,slected)=>
    {
        sessionStorage.setItem("selected",slected);   
        navigate(page);
    
    }
    
      /////////////////docs pagination////////////////////////////
    const navigateTabSelected = (slected) => 
    {

            sessionStorage.setItem(slected,"selected");    
        
        
    }
    const getTopTenReminders=()=>
    {
       var vechileReq=
        {
          
            
           
        }
    
        var requestOptions=requestObject.RequestHeader(vechileReq);
        services.getTopTenReminders(requestOptions).then((res) => 
        {
          
            if(res.success)
            {

              setTotalItems(res.customValues.totalNumberOfRecords);
            
              settotalPages(res.customValues.totalNumberOfRecords);
             
              setCurrentPage(res.customValues.currentPageNumber);
              if(res.rows.length>0)
              {
                 
                setdashBoardAgreggation(res.rows);
              }else{

                
                setdashBoardAgreggation("");
              }
             
              setTotalRecord(res.customValues.totalNumberOfRecords);
        
              var displaypages=[];
            
              
                      for(var i=1;i<=parseInt(Math.ceil(res.customValues.totalNumberOfRecords/ITEMS_PER_PAGE));i++)
                      {
                       
                        displaypages.push(i*ITEMS_PER_PAGE);
                      }
                      
                      setdisplayPage(displaypages);
              
                    }
          
    
           
        });

    }

    const accountType = () => 
    {
        var customerListReq =
        {
  
  
  
        };
  
        var requestOptions = requestObject.RequestHeader(customerListReq);
        services.getAccountType(requestOptions).then((res) => 
        {
            console.log("results="+JSON.stringify(res))
            if (res.customValues) {
                $("#fleetcompanyLogo").attr("src", "data:image/png;base64," + res.customValues.fileByte);
             }
            // if(res.customValues)
            // {
            //     try{
            //         sessionStorage.setItem("fleetCompanyLogo",res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/fleeto-icon.png" );
            //         $("#fleetCompanyLogo").attr("src","data:image/png;base64,"+res.customValues.fileByte);
            //     }
            //     catch(error)
            //     {
                

            //     }
               
            // }else{
            //     try
            //     {
            //         sessionStorage.setItem("fleetCompanyLogo", "/images/fleeto-icon.png" );
            //     }catch(error)
            //     {
                

            //     }
               
            // }
            sessionStorage.setItem("comapnyName",res.rows ? res.rows.name:"");
            $("#comapnyName").html(res.rows ? res.rows.name:"")
            // if(res.customValues)
            // {
               
            //     try{
            //         alert("if"+res.customValues.fileByte)
            //         sessionStorage.setItem("fleetCompanyLogo",res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/fleeto-icon.png" );
            //         $("#fleetCompanyLogo").attr("src",res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/fleeto-icon.png");
            //         // $("#fleetCompanyLogo").attr("src","data:image/png;base64,"+res.customValues.fileByte);
            //     }
            //     catch(error)
            //     {
                

            //     }
               
            // }else{
            //     try
            //     {
            //         sessionStorage.setItem("fleetCompanyLogo", "/images/fleeto-icon.png" );
            //     }catch(error)
            //     {
                

            //     }
               
            // }
            sessionStorage.setItem("comapnyName",res.rows ? res.rows.name:"");
            $("#comapnyName").html(res.rows ? res.rows.name:"")
            if(res.customValues)
            {
                if(res.customValues.fileByte==="")
                {
                   
                    $("#fleetcompanyLogo").attr("src","/images/fleeto-icon.png");
                  
                }else{
                    try{
                    sessionStorage.setItem("fleetCompanyLogo",res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/fleeto-icon.png" );
                    $("#fleetcompanyLogo").attr("src","data:image/png;base64,"+res.customValues.fileByte);
                    } catch(error)
                    {
                    
       
                    }
                }

            }
  
           
  

  
        });
    }
  
  return(
  <>
     <div class="main-panel" style={{"display":"none"}}>
                <div class="content-wrapper" style={{"display":"none"}}>
                    <div class="row">
                        <div class="col-md-12 grid-margin">
                            <div class="row">
                                <div class="col-12 col-xl-8 mb-xl-0">
                                    {/* <h3 class="font-weight-bold">{customerName}</h3> */}
                                    {/* <h6 class="font-weight-normal mb-0"><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry</span>
                                    </h6> */}
                                </div>
                            </div>
                        </div>
                    </div>
                 
                        {/* <div class="col-lg-4 col-md-6 mb-4">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <p class="card-title mb-0 text-uppercase">Inspections Reminder</p>
                                        <span class="fleet-dash-count">{upcoming+dues}</span>
                                    </div>
                                    <div class="t-item-wrap">
                                        <div class="t-item text-center ">
                                            <h4>{upcoming}</h4>
                                            <span class="text-uppercase">
                                                upcoming
                                            </span>
                                        </div>
                                        <div class="t-item text-center ">
                                            <h4 class="red-text">{dues}</h4>
                                            <span class="text-uppercase">
                                                dues
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div class="col-lg-4 col-md-6 mb-4">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <p class="card-title mb-0 text-uppercase">Staff/Employess</p>
                                        <span class="fleet-dash-count">37</span>
                                    </div>
                                    <div class="t-item-wrap">
                                        <div class="t-item text-center ">
                                            <h4>04</h4>
                                            <span class="text-uppercase">
                                                dispatchers
                                            </span>
                                        </div>
                                        <div class="t-item text-center ">
                                            <h4>33</h4>
                                            <span class="text-uppercase">
                                                drivers
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div class="col-lg-4 col-md-6 mb-4">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <p class="card-title mb-0 text-uppercase">Staff/Employess</p>
                                      <span class="fleet-dash-count">{staffAndEmp?staffAndEmp.length:""}</span> 
                                    </div>
                                    <div class="t-item-wrap">
                                    {
                                          staffAndEmp?staffAndEmp.map((data, index) => (
                                          <div class="t-item text-center ">
                                            <h4>{data.noOfUsers}</h4>
                                            <span class="text-uppercase">
                                            {data.securityRoleName}
                                            </span>
                                          </div>

                                           )):<div class="no-data"><span class="mt-2">No Data</span>
                                          </div>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div> */}
                          <div class="row mb-4">
                        <div class="col-lg-12">
                            <div class="dash-grid">
                               <a onClick={() => redirect("/fleetowner/reminder",'overDue')} class="db-hvr" >
                                    <div class="grid-item-db" style={{backgroundColor: "#FACE7F"}}>
                                        <div class="grid-db-icon"><img src="/images/icons/db/db-03.png" alt=""/></div>
                                        <div class="grid-bottom-db">
                                            <span class="count-db">{DashboardAggregation.DUE_INSPECTION_COUNT}</span>
                                            <span class="count-label">Overdue</span>
                                        </div>
                                    </div>
                                </a>
                                <a  class="db-hvr" onClick={() => redirect("/fleetowner/reminder",'')}>
                                    <div class="grid-item-db" style={{backgroundColor: "#9FC088"}}>
                                        <div class="grid-db-icon"><img src="/images/icons/db/upcoming.png" alt=""/></div>
                                        <div class="grid-bottom-db">
                                            <span class="count-db">{DashboardAggregation.UPCOMING_INSPECTION_COUNT}</span>
                                            <span class="count-label">Upcoming</span>
                                        </div>
                                    </div>
                                </a>
                                <a  class="db-hvr" onClick={() => redirect("/fleetowner/reminder",'inprocess')}>
                                    <div class="grid-item-db" style={{backgroundColor: "#E4CDA7"}} >
                                        <div class="grid-db-icon"><img src="/images/icons/db/in-progress.png" alt=""/></div>
                                        <div class="grid-bottom-db">
                                            <span class="count-db" >{DashboardAggregation.INPROCESS_INSPECTION_COUNT}</span>
                                            <span class="count-label">Inprocess</span>
                                        </div>
                                    </div>
                                </a>
                                <a  class="db-hvr" onClick={() => redirect("/fleetowner/reminder",'completed')}>
                                    <div class="grid-item-db" style={{backgroundColor: "#FE8F8F"}} >
                                        <div class="grid-db-icon"><img src="/images/icons/db/completed.png" alt=""/></div>
                                        <div class="grid-bottom-db">
                                            <span class="count-db" >{DashboardAggregation.COMPLETED_INSPECTION_COUNT}</span>
                                            <span class="count-label">Completed</span>
                                        </div>
                                    </div>
                                </a>
                              
                                <a  class="db-hvr" onClick={() => redirect("/fleetowner/Vehicle",'')}>
                                    <div class="grid-item-db" style={{backgroundColor: "#90AACB"}}>
                                        <div class="grid-db-icon"><img src="/images/icons/db/db-05.png" alt=""/></div>
                                        <div class="grid-bottom-db">
                                            <span class="count-db" >{DashboardAggregation.VEHICLE_COUNT}</span>
                                            <span class="count-label">Vehicles</span>
                                        </div>
                                    </div>
                                </a>
                               
                                <a  class="db-hvr" onClick={() => redirect("/fleetowner/Vehicle",'documents')}>
                                    <div class="grid-item-db" style={{backgroundColor: "#D8B6A4"}}>
                                        <div class="grid-db-icon"><img src="/images/icons/db/db-06.png" alt=""/></div>
                                        <div class="grid-bottom-db">
                                            <span class="count-db" >{DashboardAggregation.DOCUMENT_COUNT}</span>
                                            <span class="count-label">Documents</span>
                                        </div>
                                    </div>
                                </a>

                            </div>
                        </div>
                    </div>
                 
                    <div class="row mb-4">
                        <div class="col-md-12 grid-margin stretch-card mb-0">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center mb-4">
                                        <p class="card-title mb-0 text-uppercase">Upcoming / Overdue</p>
                                        {/* <span class="fleet-dash-count">4</span> */}
                                        <a  onClick={() => redirect("/fleetowner/reminder",'')} class="btn btn-outline-primary text-uppercase">View ALL</a>
                                    </div>
                                    <div class="table-responsive  border-bottom">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Unit Number</th>
                                                    <th>VIN Number</th>
                                                    <th>Inspection</th>
                                                    <th>Expiry Date</th>
                                                
                                                    <th width="45">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                               {
                                                                                                                        dashboardAggregationData?dashboardAggregationData.map((data, index) => (
                                                                                                                        <tr>
                                                                                                                         <td style={{width:"150px", whiteSpace:"break-spaces", lineHeight:"18px"}}>{data.unitNumber ? data.unitNumber : "--"}</td>
                                                                                                                        <td style={{width:"150px", whiteSpace:"break-spaces", lineHeight:"18px"}}>{data.vinNumber ? data.vinNumber : "--"} </td>
                                                                                                                        <td style={{width:"auto", whiteSpace:"break-spaces", lineHeight:"18px"}}>{data.documentSubCategoryName ? data.documentSubCategoryName : "--"}</td>
                                                                                                                        {/* <td style={{width:"auto", whiteSpace:"break-spaces", lineHeight:"18px"}}>{data.docSubCategory ? data.docSubCategory : "--"}</td> */}
                                                                                                                        <td style={{width:"100px", whiteSpace:"break-spaces", lineHeight:"18px"}}>{data.expiryDate ? moment(data.expiryDate).format('MM-DD-YYYY') : "--"}</td>
                                                                                                                        <td style={{width:"180px", maxWidth:"180px", whiteSpace:"break-spaces", lineHeight:"18px"}}><a  onClick={() => redirect("/fleetowner/VehicleInformation/" + data.id,'')} class="btn btn-outline-primary text-uppercase">View</a>&nbsp;  
                                                                                                                        {roles.match("Driver")?"":<a onClick={() => vehicleInfo(data.id,data.documentId,data.documentSubCategoryName)}  class="btn btn-primary text-uppercase">Resolve</a>}
                                                                                                                        </td>
                                                                                                                        </tr>
                                                                                                                        )):<div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                                                                                                                        </div>
                                                }
                                                {/* <tr>
                                                    <td>ERS 8755 </td>
                                                    <td>IPMS44324T1017489 </td>
                                                    <td>Leakage Test</td>
                                                    <td>Prestonwing </td>
                                                    <td>(617) 399 8424</td>
                                                    <td>2 days ago</td>
                                                    <td><a href="#" class="btn btn-outline-primary text-uppercase">View</a></td>
                                                </tr> */}
                                             

                                            </tbody>
                                        </table>
                                    </div>
                                   
                                </div>
                            </div>
                          
                        </div>

                    </div>



                </div>
            </div>
  </>
)};

FleetOwnerDashboard.propTypes = {};

FleetOwnerDashboard.defaultProps = {};

export default FleetOwnerDashboard;
