
const router = (function () {
  // reference to the singleton
  var instance;

  function init() {
    // private
    const routes = {
      home: {
        path: '/home'
      },
      customer: {
        path: '/customer'
      },
      addusers: {
        path: '/addusers'
      },
      editUser:{
        path: '/editUser/:id'
      },
      customerDetails: {
        path: '/CustomerDetails/:id/'
      },
      addCustomer: {
        path: '/addCustomer/:id/'
      },
      userDetails: {
        path: '/userDetails/:userId/'
      },
      profile: {
        path: '/profile'
      },
      settings: {
        path: '/settings'
      },
      fleetownerprofile: {
        path: '/fleetowner/profile'
      },
      fleetownersettings: {
        path: '/fleetowner/settings'
      },
      vehicles: {
        path: '/vehicles'
      },
      vehicleInfo:
      {
        path: '/vehiclInfo'
      },
      vehicleDetails: {
        path: '/vehicleDetails/:id'
      },
      inspectionOrderList: {
        path: '/inspectionOrderList/:id'
      },
      inspectionOrders: {
        path: '/inspectionOrders/:id'
      },
      addVehicle: {
        path: '/addVehicle/:id'
      },
      editVehicle: {
        path: '/editVehicle/:id'
      },
      // addVehicleTrailer: {
      //   path: '/addVehicleTrailer'
      // },
      // addVehicleTruck: {
      //   path: '/addVehicleTruck'
      // },
      documents: {
        path: '/documents'
      },
      addDocument: {
        path: '/addDocument/:customerId/:vehicleId'
      },
      editDocument: {
        path: '/editDocument/:customerId/:vehicleId/:documentId'
      },
      inspectionVaporTightnessCertificate1: {
        path: '/inspectionVaporTightnessCertificate1'
      },
      inspectionVaporTightnessCertificate2: {
        path: '/inspectionVaporTightnessCertificate2'
      },
      inspectionVaporTightnessCertificateRead: {
        path: '/inspectionVaporTightnessCertificateRead/:fkAccountId/:inspectionId/:vid'
      },
      inspectionVaporTightnessCertificateEdit: {
        path: '/inspectionVaporTightnessCertificateEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid'
      },
      annualVehicle1: {
        path: '/annualVehicle1'
      },
      annualVehicle2: {
        path: '/annualVehicle2'
      },
      annualVehicle3: {
        path: '/annualVehicle3'
      },
      annualVehicle4: {
        path: '/annualVehicle4'
      },
      annualVehicle5: {
        path: '/annualVehicle5'
      },
      annualVehicleRead: {
        path: '/annualVehicleRead/:fkAccountId/:inspectionId/:vid'
      },

      annualVehicle1Edit: {
        path: '/annualVehicle1Edit/:taskOrderId/:fkAccountId/:inspectionId/:vid'
      },

      cargoTankThicknessTest: {
        path: '/cargoTankThicknessTest'
      },

      cargoTankThicknessTestRead: {
        path: '/cargoTankThicknessTestRead/:taskOrderId/:fkAccountId/:inspectionId/:vid'
      },
      cargoTankThicknessTestEdit: {
        path: '/cargoTankThicknessTestEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid'
      },
      carrierEquipment: {
        path: '/carrierEquipment'
      },

      carrierEquipmentRead: {
        path: '/carrierEquipmentRead/:fkAccountId/:inspectionId/:vid'
      },
      carrierEquipmentEdit: {
        path: '/carrierEquipmentEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid'
      },

      dotCargo: {
        path: '/dotCargo'
      },

      dotCargoRead: {
        path: '/dotCargoRead/:fkAccountId/:inspectionId/:vid'
        // path: '/dotCargoRead'
      },
      dotCargoEdit: {
        path: '/dotCargoEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid'
      },
      externalVisualSectionOne: {
        path: '/externalVisualSectionOne'
      },

      externalVisualSectionTwo: {
        path: '/externalVisualSectionTwo'
      },

      externalVisualSectionThree: {
        path: '/externalVisualSectionThree'
      },

      externalVisualSectionFour: {
        path: '/externalVisualSectionFour'
      },

      externalVisualSectionFive: {
        path: '/externalVisualSectionFive'
      },

      externalVisualSectionSix: {
        path: '/externalVisualSectionSix'
      },

      externalVisualRead: {
        path: '/externalVisualRead/:fkAccountId/:inspectionId/:vid'
      },

      editExternalVisual: {
        path: '/editExternalVisual/:taskOrderId/:fkAccountId/:inspectionId/:vid'
      },

      gasolineDeliveryTankPressureVacuumTest: {
        path: '/gasolineDeliveryTankPressureVacuumTest'
      },

      gasolineDeliveryTankPressureVacuumTestRead: {
        path: '/gasolineDeliveryTankPressureVacuumTestRead/:fkAccountId/:inspectionId/:vid'
      },
      gasolineDeliveryTankPressureVacuumTestEdit: {
        path: '/gasolineDeliveryTankPressureVacuumTestEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid'
      },
      internalVisual: {
        path: '/internalVisual'
      },

      internalVisualRead: {
        path: '/internalVisualRead/:fkAccountId/:inspectionId/:vid'
      },

      internalVisualEdit: {
        path: '/internalVisualEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid'
      },
    
      addInpection: {
        path: '/addInpection/:taskOrderId/:cid/:vid'
      },

      leakageTest: {
        path: '/leakageTest/:taskOrderId/:cid/:id'
      },

      leakageTestRead: {
        path: '/leakageTestRead/:fkAccountId/:inspectionId/:vid'
      },
      leakageTestEdit: {
        path: '/leakageTestEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid'
      },

      leakageTestMethod27: {
        path: '/leakageTestMethod27/:taskOrderId/:cid/:id'
      },

      leakageTestMethod27Read: {
        path: '/leakageTestMethod27Read/:fkAccountId/:inspectionId/:vid'
      },
      leakageTestMethod27Edit: {
        path: '/leakageTestMethod27Edit/:taskOrderId/:fkAccountId/:inspectionId/:vid'
      },
      pressureTest: {
        path: '/pressureTest'
      },

      pressureTestRead: {
        path: '/pressureTestRead/:fkAccountId/:inspectionId/:vid'
      },
      pressureTestEdit: {
        path: '/pressureTestEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid'
      },
      wetTestCertification: {
        path: '/wetTestCertification'
      },

      wetTestCertificationRead: {
        path: '/wetTestCertificationRead/:fkAccountId/:inspectionId/:vid'
      },
      wetTestCertificationEdit: {
        path: '/wetTestCertificationEdit/:taskOrderId/:fkAccountId/:inspectionId/:vid'
      },
      upcomingOverdue: {
        path: '/upcomingOverdue'
      },
      // Fleet Owner

      fleetownerProfile: {
        path: '/fleetowner/profile'
      },

      fleetownerProfile: {
        path: '/fleetowner/profile'
      },

      // fleetownerAddVehicle: {
      //   path: '/fleetowner/addVehicle/:id'
      // },
      fleetownerAddVehicle: {
        path: '/fleetowner/AddVehicle'
      },
      fleetownerVehicle: {
        path: '/fleetowner/Vehicle'
      },
      fleetownerVehicleInformation: {
        path: '/fleetowner/VehicleInformation/:id'
      },
      fleetownerEditVehicle: {
        path: '/fleetowner/FleetEditVehicle/:id'
      },
      fleetownerVehicleAtGarage: {
        path: '/fleetowner/VehicleAtGarage'
      },

      

      fleetAddDispatcher: {
        path: '/fleetowner/AddDispatcher'
      },

      fleetDispatcherDetails: {
        path: '/fleetowner/DispatcherDetails/:userId'
      },

      fleetEditDispatcher: {
        path: '/fleetowner/EditDispatcher/:userId'
      },

      fleetAssignToGarrage: {
        path: '/fleetowner/AssignToGarrage/:id'
      },
      

      fleetAddDriver: {
        path: '/fleetowner/AddDriver'
      },

      fleetEditDriver: {
        path: '/fleetowner/EditDriver/:userId'
      },

      fleetDriverDetails: {
        path: '/fleetowner/DriverDetails/:id'
      },

      employees: {
        path: '/fleetowner/employees'
      },

      AddMydocs: {
        path: '/AddMydocs/:id'
      },

      AddMyFleetdocs: {
        path: '/fleetowner/AddMyFleetdocs/:id'
      },

      fleetEditInspection: {
        path: '/fleetowner/EditInspection'
      },
      fleetownerEditVehicle: {
        path: '/fleetowner/FleetEditVehicle'
      },
      fleetAddInspection: {
        path: '/fleetowner/AddInspection/:customerId/:vehicleId'
      },
      fleetEditDocument: {
        path: '/fleetowner/EditDocument/:customerId/:vehicleId/:documentId'
      },
    
      fleetownerAddDocument: {
        path: '/fleetowner/AddDocument/:vehicleId'
      },

      reminderAssignToGarrage: {
        path: '/fleetowner/reminderAssignToGarrage'
      },

      reminder: {
        path: '/fleetowner/reminder'
      },

      upcomingInspection: {
        path: '/fleetowner/upcomingInspection'
      },

      overdueInspection: {
        path: '/fleetowner/overdueInspection'
      },
      login: {
        path: '/login'
      },
      signupGarage: {
        path: '/signupGarage'
      },
      signupFleet: {
        path: '/signupFleet'
      },
      pdfView: {
        path: '/document/pdfViewer'
      },

      garageDashboard: {
        path: '/garage/dashboard'
      }, 
      users: {
        path: '/users'
      }, 
      fleetownerDashboard: {
        path: '/fleetowner/dashboard'
      },
      authentication: {
        path: '/authentication'
      },
      taskOrder: {
        path: '/taskOrder/:customerIdFrom/:vehicleIdFrom/:customerNameFrom/:vehicleVINFrom'
      },
    };

    function getRouteInfo(routeName) {
      //alert(routeName);
      return routes[routeName];
    }

    function getRoutesInfo(routeNames) {
      const routesInfo = {};

      routeNames.forEach(route => {
        routesInfo[route] = routes[route];
      });


      return routesInfo;
    }

    function getLinksInfo(routeNames) {
      const links = {};

      routeNames.forEach(route => {
        links[route] = routes[route];
        links[route].pathName = links[route].path;
      });

      return links;
    }



    return {
      getRouteInfo,
      getRoutesInfo,
      getLinksInfo
    };
  }

  return {
    // get instance if one exists
    // or create one it it doesn't

    getInstance: function () {
      if (!instance) {
        instance = init();
      }

      return instance;
    },
  };
})();

export default router;
