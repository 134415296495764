import React, { history, useEffect, useState, useMemo } from "react";
import services from "../../../../services/services";
import { requestObject } from "../../../../utility/requestObject";
import { useNavigate, useParams } from "react-router-dom";
import $ from "jquery";
import Swal from "sweetalert2";
import { loadingComponent } from "../../../../utility/Loading";
import { dataURItoByteString } from "react-pdf/dist/umd/shared/utils";
import { Driver, Dispatcher} from "../../../constant";
const FleetDriverDetails = () => {
    const navigate = useNavigate();
    const [driverDetails, setDriverDetails] = useState("");
    const { id } = useParams();

    var roles = localStorage.getItem("Userroles");

    useEffect(() => {

        getDriverDetails();
    }, [])

    const getDriverDetails = () => {
        $("#loading").show();
        var requestObj =
        {
            "id": id
        }
        var requestOptions = requestObject.RequestHeader(requestObj);
        services.getUserDetails(requestOptions).then((res) => {
            $("#loading").hide();
            setDriverDetails(res.rows);
            $("#driverImage").attr("src", res.customValues ? res.customValues.userImageFileByte ? "data:image/png;base64," + res.customValues.userImageFileByte : "/images/avatar.png" : "/images/avatar.png");
        })

    }
    const redirect=(page)=>
    {

        navigate(page);

    }
    const document_details = (id, filePath) => {

        var extension = filePath.match('[^.]+$');

        var document_request =
        {

            "id": id

        };

        var requestOptions = requestObject.RequestHeader(document_request);

        services.getUserDocumentDetails(requestOptions).then((res) => {
            try {

                if (res.customValues.fileByte === "") {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: "",
                        text: "File is not attached",

                        showConfirmButton: true

                    }).then((result) => {
                        if (result.isConfirmed) {

                        }
                    });
                }
                else {

                    var file;

                    if (extension == "pdf") {
                        var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
                        var byteNumbers = new Array(byteCharacters.length);
                        for (var i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        var byteArray = new Uint8Array(byteNumbers);
                        file = new Blob([byteArray], { type: 'application/pdf;base64' });
                        var fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    }
                    else if (extension == "jpg") {
                        // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                        var image = new Image();
                        image.src = "data:image/jpg;base64," + res.customValues.fileByte;

                        var w = window.open("");
                        w.document.write(image.outerHTML);
                    }
                    else if (extension == "png") {
                        // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                        var image = new Image();
                        image.src = "data:image/png;base64," + res.customValues.fileByte;

                        var w = window.open("");
                        w.document.write(image.outerHTML);
                    }
                    else if (extension == "bmp") {
                        // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                        var image = new Image();
                        image.src = "data:image/bmp;base64," + res.customValues.fileByte;

                        var w = window.open("");
                        w.document.write(image.outerHTML);
                    }
                    else if (extension == "word") {

                        var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
                        var byteNumbers = new Array(byteCharacters.length);
                        for (var i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        var byteArray = new Uint8Array(byteNumbers);
                        file = new Blob([byteArray], { type: 'application/msword;base64' });
                        var fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    }
                    else if (extension == "docx" || extension == "doc" || extension == "docm" || extension == "dotx" || extension == "dotm") {

                        var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
                        var byteNumbers = new Array(byteCharacters.length);
                        for (var i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        var byteArray = new Uint8Array(byteNumbers);
                        file = new Blob([byteArray], { type: 'application/msword;base64' });
                        var fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    }
                }
            } catch (ex) {

            }



        });

    }
    return (
        <>
         {loadingComponent}
            <div class="main-panel">
                <div class="content-wrapper" style={{ display: "block" }}>
                    <div class="col-lg-11 col-md-12 m-auto">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-flex mb-3 align-items-center justify-content-between">
                                    <h4 class="font-weight-bold text-uppercase">Driver Detail</h4>
                                    <a  onClick={() => redirect("/fleetowner/employees")} class="btn btn-white"><span style={{ size: "20px" }}>&lArr;</span>&nbsp;&nbsp;Back</a>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="card p-3 mb-2">
                                    <div class="banner-owner">
                                        <div class="row">
                                            <div class="col">
                                                <div class="owner-logo mb-3">
                                                    <img alt="" style={{ width: "70px" }} id="driverImage" />
                                                    <div class="owner-name">
                                                        <h4 class="mb-0">{driverDetails.firstName+"    "+driverDetails.lastName}  <label class="badge badge-secondary text-uppercase text-white mb-0 ml-1">{driverDetails.securityRoleName}</label></h4>
                                                        <p class="opacity-7">EMP ID: <b>{driverDetails ? driverDetails.id : "N/A"}</b></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                            {roles.match(Driver) || roles.match(Dispatcher) ?"":  <a  onClick={() => redirect("/fleetowner/EditDriver/" + driverDetails.id)}><img src="/images/icons/edit-icon.svg" alt="" class="mr-1" />Edit</a>}
                                            </div>
                                        </div>

                                        <div class="banner-contact-info">
                                            <div class="row">
                                                <div class="col-md-5">
                                                    <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                                        <img src="/images/icons/map-icon.svg" alt="" class="mr-2" />
                                                        <div style={{ minWidth: "240px" }}> 
                                                        <span>{driverDetails ? driverDetails.addressLine1:""}{driverDetails ? driverDetails.addressLine1 ? ', ':"":""}</span>
                                                        <span>{driverDetails ? driverDetails.addressLine2:""}{driverDetails ? driverDetails.addressLine2 ? ', ':"":""}</span><br />
                                                        <span>{driverDetails ? driverDetails.city : ""}{driverDetails ? driverDetails.city ? ', ' : "" :""}</span>
                                                        <span>{driverDetails ? driverDetails.stateName: ""}{driverDetails ? driverDetails.stateName ? ', ' : "":""}</span>
                                                        <span>{driverDetails ? driverDetails.zip : ""}{driverDetails ? driverDetails.zip ? '.' : "":""}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                                        <img src="/images/icons/phone-icon.svg" alt="" class="mr-2" />
                                                        <span>{driverDetails.phoneNumber}</span>
                                                    </div>
                                                </div>
                                                {/* <div class="col-md-3">
                                                    <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                                        <img src="/images/icons/fax-icon.svg" alt="" class="mr-2" />
                                                        <span>{driverDetails.deskNumber}</span>
                                                    </div>
                                                </div> */}
                                                <div class="col-md-4">
                                                    <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                                                        <img src="/images/icons/message-icon.svg" alt="" class="mr-2" />
                                                        <span>{driverDetails.email}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div class="card mb-2">
                                    <div class="col-md-12 pt-4 pr-4 pl-4">
                                        <h3>On Vehicle Duty</h3>
                                    </div>
                                    <div class="vehicle_duty_wp d-flex p-4">
                                        <div class="vehicle_dtls ">
                                            <label>Vehicle Number</label>
                                            <h4 class="mb-0">ERS 8547</h4>
                                        </div>
                                        <div class="vehicle_dtls">
                                            <label>Reporting to</label>
                                            <h4 class="mb-0">William James</h4>
                                        </div>
                                    </div>
                                </div> */}
{roles.match(Driver) || roles.match(Dispatcher) ?"":
                                <div class="card mb-2">
                                    <div class="header-top-bg p-3 ">
                                        <h4>Driving Licence & Documents</h4>
                                    </div>
                                    <div class="row px-3" style={{ marginBottom: "30px"}} >
                                        {
                                            driverDetails.documents ? driverDetails.documents.map((data, index) => (

                                                <div class="col-md-6">
                                                    <a style={{ cursor: "pointer" }} onClick={() => document_details(data.id, data.filePath)} class="ml-2">
                                                        {/* <div class="proof_doc_container mt-3">
                                                                                                            <label style={{cursor:"pointer",color:"#3f88c5"}}>{data.name ? data.name : "--"}</label>
                                                                                                           <div class="proof_doc_wp">

                                                                                                                <img src="/images/icons/pdf-icon.svg"/>
                                                                                                            </div>
                                                                                                          <div class="mb-3 d-flex flex-column">
                                                                                                                <label class="mb-1 opacity-7"><a onClick={() => document_details(data.id, data.filePath)} >{data.name ? data.name : "--"}</a></label>
                                                                                                                <b>XXXXXXXXXXX</b>
                                                                                                            </div>
                                                                                                        </div> */}

                                                        <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                            <img src="" class="mr-2" />
                                                            <span><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>  */}
                                                                {(() => {
                                                                            if (data.filePath.split(".")[1] == 'pdf') {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            } else if (data.filePath.split(".")[1] == 'jpg' || data.filePath.split(".")[1] == 'png' || data.filePath.split(".")[1] == 'jpeg' || data.filePath.split(".")[1] == 'bmp') {
                                                                                return (
                                                                                    <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else if (data.filePath.split(".")[1] == 'doc' || data.filePath.split(".")[1] == 'word' || data.filePath.split(".")[1] == 'docx' || data.filePath.split(".")[1] == 'dot' || data.filePath.split(".")[1] == 'dotm' || data.filePath.split(".")[1] == 'dotx') {
                                                                                return (
                                                                                    <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                )
                                                                            }
                                                                        })()}
                                                                {data.name ? data.name+''+data.filePath.split(".")[1] : "--"} </p></span>
                                                        </div>
                                                    </a>

                                                </div>


                                            )) : <div class="col-md-4">
                                                <div class="proof_doc_container mt-3">
                                                    <label>No documents added</label>
                                                    {/* <div class="proof_doc_wp" style={{height: "100%"}}>
                                             <img src="/images/no-vehicle-image.png" />
                                             </div> */}
                                                </div>
                                            </div>


                                        }
                                    </div>
                                    {/* </div> */}
                                    {/* <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="proof_doc_container mt-3">
                                                    <label>Driving Licence</label>
                                                    <div class="proof_doc_wp">
                                                        <span class="edit-cust"><a href="#"><img
                                                                    src="images/icons/edit-icon.svg"/></a></span>
                                                        <img src="images/id_proof.jpg"/>
                                                    </div>
                                                    <div class="mb-3 d-flex flex-column">
                                                        <label class="mb-1 opacity-7">License Number</label>
                                                        <b>86127876</b>
                                                    </div>
                                                    <div class="mb-3 d-flex flex-column">
                                                        <label class="mb-1 opacity-7">DL Expiry Date</label>
                                                        <b>20-07-2034</b>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="proof_doc_container mt-3">
                                                    <label>ID Proof Title</label>
                                                    <div class="proof_doc_wp">
                                                        {/* <!-- <span class="edit-cust"><a href="#"><img
                                                                    src="images/icons/edit-icon.svg"></a></span>
                                                        <img src="../DotStow-garrage/images/vehicle_info.png"> -->
                                                    </div>
                                                    <div class="mb-3 d-flex flex-column">
                                                        <label class="mb-1 opacity-7">XXXXXXXXXXXXXXXX</label>
                                                        <b>XXXXXXXXXXX</b>
                                                    </div>
                                                    {/* <!-- <div class="mb-3 d-flex flex-column">
                                                        <label class="mb-1 opacity-7">DL Expiry Date</label>
                                                        <b>20-07-2034</b>
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="proof_doc_container mt-3">
                                                    <label>ID Proof Title</label>
                                                    <div class="proof_doc_wp">
                                                        {/* <!-- <span class="edit-cust"><a href="#"><img
                                                                    src="images/icons/edit-icon.svg"></a></span>
                                                        <img src="../DotStow-garrage/images/vehicle_info.png"> -->
                                                    </div>
                                                    <div class="mb-3 d-flex flex-column">
                                                        <label class="mb-1 opacity-7">XXXXXXXXXXXXXXXX</label>
                                                        <b>XXXXXXXXXXX</b>
                                                    </div>
                                                    {/* <!-- <div class="mb-3 d-flex flex-column">
                                                        <label class="mb-1 opacity-7">DL Expiry Date</label>
                                                        <b>20-07-2034</b>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

FleetDriverDetails.propTypes = {};

FleetDriverDetails.defaultProps = {};

export default FleetDriverDetails;